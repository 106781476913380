import { Autocomplete, Box, Button, Container, Divider, IconButton, Menu, Stack, TextField, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import { useNavigate } from "react-router-dom"
import { addService, deleteService, getServices } from "../Api/Admin_api"
import DeleteIcon from "@mui/icons-material/Delete"
import ClearIcon from "@mui/icons-material/Clear"
import CheckIcon from "@mui/icons-material/Check"
import { useUser } from "../Context/User_context"
import { NumericFormat } from "react-number-format"
import EditableItem from "../Components/EditableItem"

const Services = () => {

    const { setNotification } = useUser()

    const navigate = useNavigate()

    const [frequency, setFrequency] = useState([
        "Daily",
        "Bi-Daily",
        "Tri-Daily",
        "Weekly",
    ])

    const handleDeleteService = async (e: any) => {
        const result = await deleteService(anchorEl.id)
        setNotification({ type: result.type, msg: result.msg })
        setRows((prev: any) => prev.filter((row: any) => `${row.id}` !== `${anchorEl.id}`))
        setVisibleRows((prev: any) => prev.filter((row: any) => `${row.id}` !== `${anchorEl.id}`))
        setAnchorEl(null)
    }

    const [anchorEl, setAnchorEl] = useState<any>(null)

    const columns = [
        { field: "id", headerName: "ID" },
        { field: "name", headerName: "Package Name", flex: 1,
            renderCell: (params: any) => 
                <EditableItem
                onBlur={(v: any) => {
                    const result = addService({ ...params.row, name: v })
                    setRows((prev: any) => prev.map((item: any) => (
                        item.id === params.id ?
                            { ...item, name: v }
                        :
                            item
                        ))
                    )
                }}
                >
                    {params.row.name}
                </EditableItem>
        },
        { field: "frequency", headerName: "Frequency", flex: 1,
            renderCell: (params: any) =>
                <Autocomplete
                size="small"
                sx={{ width: "100%" }}
                options={frequency}
                defaultValue={params.row.frequency}
                onChange={async (e: any, v: any) => {
                    if (v) {
                        const result = await addService({ ...params.row, frequency: v })
                        if (result.type !== "error") {
                            setRows((prev: any) => prev.map((item: any) => (
                                item.id === params.id ?
                                    { ...item, frequency: v }
                                :
                                    item
                                ))
                            )
                        }
                    }
                }}
                renderInput={(params: any) => <TextField {...params} label="Frequency" />}
                />
        },
        { field: "price", headerName: "Price", flex: 1,
            renderCell: (params: any) =>
                <NumericFormat
                fullWidth
                size="small"
                value={params.row.price}
                thousandSeparator={true}
                prefix={"$ "}
                placeholder="$ 0.00"
                customInput={TextField}
                fixedDecimalScale
                decimalScale={2}
                onBlur={async (e: any) => {
                    const result = await addService({ ...params.row, price: e.target.value.replace(/[^\d.]/g, "") })
                    if (result.type !== "error") {
                        setRows((prev: any) => prev.map((item: any) => (
                            item.id === params.id ?
                                { ...item, price: e.target.value }
                            :
                                item
                            ))
                        )
                    }
                }}
                allowNegative={false}
                allowLeadingZeros={false}
                isAllowed={(values) => {
                    const {floatValue} = values
                    return (floatValue ?? 0) >= 0
                }}
                />
        },
        { field: "delete", headerName: "",
            renderCell: (params: any) => 
                <IconButton
                id={params.row.id}
                color="error"
                size="small"
                onClick={(e: any) => {e.stopPropagation(); setAnchorEl(e.currentTarget)}}
                >
                    <DeleteIcon />
                </IconButton>
        },
    ]

    const [searchInput, setSearchInput] = useState("")

    const [rows, setRows] = useState<any>([])
    const [visibleRows, setVisibleRows] = useState<any>([])

    const handleAddService = async () => {
        const result = await addService({
            name: `Service ${rows.length + 1}`,
            frequency: "Weekly",
            price: 0,
        })
        if (result.type === "error") {
            setNotification({ type: result.type, msg: result.msg })
        } else {
            setRows((prev: any) => [ ...prev, result ])
            setVisibleRows((prev: any) => [ ...prev, result ])
        }
    }

    useEffect(() => {
        getServices().then((result: any) => {
            setRows(result)
            setVisibleRows(result)
        })
    }, [])

    return (
        <Container sx={{ display: "flex", gap: 2, width: "100%", height: "100%" }}>
            <Stack gap={3} sx={{ flexGrow: 1 }}>
                {/* <Typography variant="h4">Services</Typography>
                <Divider /> */}
                <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "flex-end" }}>
                    <Typography variant="h4">Services</Typography>
                    <Box sx={{ display: "flex", gap: 1 }}>
                        <TextField size="small" placeholder="Search Services" value={searchInput} onChange={(e: any) => {setSearchInput(e.target.value)}} />
                        <Button onClick={handleAddService} startIcon={<AddCircleIcon />} size="small" variant="contained">new service</Button>
                    </Box>
                </Box>
                <DataGrid
                sx={{ flexGrow: 1 }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                // onRowClick={(p: any) => {navigate(`/services/${p.row.id}`)}}
                // getRowClassName={(params: any) => "enabled-row"}
                columns={columns}
                rows={visibleRows}
                />
            </Stack>
            <Menu
            anchorEl={anchorEl}
            open={anchorEl ? true : false}
            >
                <Stack gap={1} sx={{ paddingX: 2, paddingY: 1 }}>
                    <Typography variant="caption">Are you sure you want to delete?</Typography>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <IconButton size="small" onClick={handleDeleteService}>
                            <CheckIcon />
                        </IconButton>
                        <IconButton size="small" onClick={() => {setAnchorEl(null)}}>
                            <ClearIcon />
                        </IconButton>
                    </Box>
                </Stack>
            </Menu>
        </Container>
    )
}

export default Services