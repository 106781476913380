import { Box, Button, Chip, Container, Divider, IconButton, Modal, Paper, Stack, TextField, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { useNavigate, useParams } from "react-router-dom"
import { useUser } from "../Context/User_context"
import { useEffect, useState } from "react"
import { getCheckin, getUser, updateHorse } from "../Api/Admin_api"
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone"
import dayjs from "dayjs"
import CheckIcon from "@mui/icons-material/Check"
import DownloadIcon from "@mui/icons-material/Download"
import CheckinCreateModal from "../Components/CheckinCreateModal"
import CheckinViewModal from "../Components/CheckinView"
import ChangeDate from "../Components/ChangeDate"

const dateFrequencyMap = (freq: string, updatedAt: Date)  => {
    const map: any = {
       "Daily": dayjs(updatedAt).add(1, "day"),
        // "Bi-Daily": new Date(updatedAt.getDate() + 1),
        // "Weekly": new Date(updatedAt.getDate() + 7),
        // "Bi-Weekly": new Date(updatedAt.getDate() + 4),
        // "Tri-Weekly": new Date(updatedAt.getDate() + 2),
        // "Daily": dayjs(updatedAt).add(1, "day"),
        // "Bi-Daily": dayjs(updatedAt).add(6, "hour"),
        // "Tri-Daily": dayjs(updatedAt).add(4, "hour"),
        "Weekly": dayjs(updatedAt).add(7, "day"),
        "Bi-Weekly": dayjs(updatedAt).add(4, "day"),
        "Tri-Weekly": dayjs(updatedAt).add(2, "day"),
    }
    return map[freq]
}

const EditHorse = () => {

    const navigate = useNavigate()
    const { selectedCustomer, setNotification, setSelectedCustomer } = useUser()
    const { horseId, customerId } = useParams()

    const [menu, setMenu] = useState<boolean | string>(false)

    const [updateRows, setUpdateRows] = useState([])

    const [fileRows, setFileRows] = useState<any>([])

    const [selectedHorse, setSelectedHorse] = useState<any>({
        name: "",
        updatedAt: "",
        services: [],
        requests: [],
        serviceRequests: []
    })

    const handleDownload = async (link: string, fileName: string) => {
        try {
            const response = await fetch(link);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const [selectedCheckin, setSelectedCheckin] = useState({})

    const handleSaveNotes = () => {
        if (horseId) {
            updateHorse({
                notes: selectedHorse.notes
            }, horseId)
        }
    }

    useEffect(() => {
        if (!selectedCustomer.Horses) {
            getUser(parseInt(customerId ?? "-1")).then((result: any) => {
                if (result.type === "error") {
                    setNotification({ type: result.type, msg: result.msg })
                } else {
                    setSelectedCustomer(result)
                    const myHorse = result.Horses.find((horse: any) => horse.id === parseInt(horseId ?? "-1"))
                    const parsedHorse = {
                        ...myHorse,
                        requests: myHorse.requests ? JSON.parse(myHorse.requests) : [],
                        services: myHorse.services ? JSON.parse(myHorse.services) : [],
                    }
                    setFileRows([ ...parsedHorse.files.map((f: any, index: number) => ({ ...f, id: index })), ...parsedHorse.images.map((f: any, index: number) => ({ ...f, id: index + parsedHorse.files.length })) ])
                    setSelectedHorse(parsedHorse)
                }
            })
        } else {
            const myHorse = selectedCustomer.Horses.find((horse: any) => horse.id === parseInt(horseId ?? "-1"))
            const parsedHorse = {
                ...myHorse,
                requests: myHorse.requests ? JSON.parse(myHorse.requests) : [],
                services: myHorse.services ? JSON.parse(myHorse.services) : [],
            }
            setFileRows([ ...parsedHorse.files.map((f: any, index: number) => ({ ...f, id: index })), ...parsedHorse.images.map((f: any, index: number) => ({ ...f, id: index + parsedHorse.files.length })) ])
            setSelectedHorse(parsedHorse)
        }
        getCheckin(horseId ?? "-1").then((result: any) => {
            setUpdateRows(result)
        })
    }, [])

    const updateColumns = [
        { field: "id", headerName: "ID" },
        { field: "note", headerName: "Note", flex: 1, renderCell: (params: any) => <>{(params.row.note).length > 64 ? `${(params.row.note).slice(0, 64)}...` : params.row.note}</> },
        { field: "createdAt", headerName: "Sent", flex: 1, renderCell: (params: any) => <>{dayjs(params.row.createdAt).format("dddd, MMMM D, YYYY - h:mm A")}</> },
    ]

    const fileColumns = [
        { field: "id", headerName: "ID" },
        { field: "name", headerName: "File Name", flex: 1 },
        // { field: "private", headerName: "Internal", flex: 1, renderCell: (params: any) => params.row.private && <CheckIcon /> },
        { field: "createdAt", headerName: "", flex: 0.1, renderCell: (params: any) => <IconButton onClick={() => {handleDownload(`${params.row.dir}?type=file`, params.row.name)}}><DownloadIcon /></IconButton> },
    ]

    return (
        <Container sx={{ display: "flex", gap: 2, width: "100%" }}>
            <Stack sx={{ flexGrow: 1, gap: 1 }}>
                <Typography variant="h5">{`${selectedCustomer.fname} ${selectedCustomer.lname}'s horse, ${selectedHorse.name}`}</Typography>
                {   dayjs().isBefore(dayjs(selectedHorse.dateLeaving)) ?
                        dayjs().isAfter(dayjs(dateFrequencyMap(selectedHorse.services.notifFrequency, selectedHorse.updatedAt))) ?
                        <Paper sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2, backgroundColor: "warning.light" }}>
                            <Box sx={{ display: "flex", width: "100%", gap: 2, alignItems: "center" }}>
                                <NotificationsNoneIcon />
                                <Typography variant="h5">Last Check in was sent:</Typography>
                                <Typography fontWeight={300} variant="h5">{dayjs(selectedHorse.updatedAt).format("dddd, MMMM D, YYYY - h:mm A")}</Typography>
                            </Box>
                        </Paper>
                        :
                        <Paper sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2, backgroundColor: "success.light" }}>
                            <Box sx={{ display: "flex", width: "100%", gap: 2, alignItems: "center" }}>
                                <CheckIcon />
                                <Typography variant="h5">Next Check in required at:</Typography>
                                <Typography fontWeight={300} variant="h5">{dayjs(dateFrequencyMap(selectedHorse.services.notifFrequency, selectedHorse.updatedAt)).format("dddd, MMMM D, YYYY - h:mm A")}</Typography>
                            </Box>
                        </Paper>
                    :
                    <Paper sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2 }}>
                        <Box sx={{ display: "flex", width: "100%", gap: 2, alignItems: "center" }}>
                            <NotificationsNoneIcon />
                            <Typography variant="h5">Horse left the facility on:</Typography>
                            <Typography fontWeight={300} variant="h5">{dayjs(selectedHorse.dateLeaving).format("dddd, MMMM D, YYYY - h:mm A")}</Typography>
                        </Box>
                    </Paper>
                }
                <Paper sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2, height: 460 }}>
                    <Box sx={{ display: "flex", width: "100%", gap: 2, alignItems: "center" }}>
                        <Typography variant="h5">Customer Check-ins</Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Button variant="contained" onClick={() => {setMenu(true)}}>new Check in</Button>
                    </Box>
                    <DataGrid
                    sortModel={[
                        {
                            field: "createdAt",
                            sort: "desc",
                        },
                    ]}
                    sx={{ flexGrow: 1 }}
                    getRowClassName={(params: any) => "enabled-row"}
                    columns={updateColumns}
                    rows={updateRows}
                    onRowClick={(p) => {setSelectedCheckin(p.row); setMenu("view")}}
                    />
                </Paper>
                <Paper sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2, height: 460 }}>
                    <Box sx={{ display: "flex", width: "100%", gap: 2, alignItems: "center" }}>
                        <Typography variant="h5">Horse's Information</Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        {/* dayjs().isBefore(dayjs(selectedHorse.dateLeaving)) && */}
                        <Button variant="outlined" onClick={() => {setMenu("change")}}>Change Tentative Leave Date</Button>
                    </Box>
                    <Divider />
                    <Box sx={{ display: "flex", gap: 2 }}>
                        <Stack gap={2}>
                            {/* <Box sx={{ display: "flex", width: "100%", gap: 2, alignItems: "center" }}>
                                <Typography fontWeight={600}>Horse's Service:</Typography>
                                <Typography>{selectedHorse?.services.name}</Typography>
                                <Box sx={{ flexGrow: 1 }} />
                            </Box> */}
                            <Box sx={{ display: "flex", width: "100%", gap: 2, alignItems: "flex-start" }}>
                                <Typography fontWeight={600}>Horse's Service Packages:</Typography>
                                <Box sx={{ width: 420, display: "flex", gap: 2, flexWrap: "wrap" }}>
                                    {selectedHorse.services.map((field: any) => (
                                        <Paper sx={{ borderRadius: 64, display: "flex", gap: 1, alignItems: "center", paddingRight: 2 }}>
                                            <Chip label={field.name} />
                                            <Typography variant="caption">{field.frequency}</Typography>
                                        </Paper>
                                    ))}
                                    {selectedHorse.serviceRequests.map((req: any) => JSON.parse(req.request)).map((field: any) => (
                                        <Paper sx={{ borderRadius: 64, display: "flex", gap: 1, alignItems: "center", paddingRight: 2, borderColor: "primary.main" }}>
                                            <Chip label={field.name} />
                                            <Typography fontStyle={"italic"} variant="caption">{field.frequency}</Typography>
                                        </Paper>
                                    ))}
                                </Box>
                                <Box sx={{ flexGrow: 1 }} />
                            </Box>
                            <Box sx={{ display: "flex", width: "100%", gap: 2, alignItems: "center" }}>
                                <Typography fontWeight={600}>Check in Frequency:</Typography>
                                <Typography>{selectedHorse.services.notifFrequency}</Typography>
                                <Box sx={{ flexGrow: 1 }} />
                            </Box>
                            <Box sx={{ display: "flex", width: "100%", gap: 2, alignItems: "flex-start" }}>
                                <Typography fontWeight={600}>Internal Notes:</Typography>
                                <TextField
                                InputProps={{
                                    style: {
                                        borderRadius: "8px",
                                    }
                                }}
                                size="small"
                                multiline
                                minRows={7}
                                value={selectedHorse.notes}
                                onChange={(e) => {setSelectedHorse((prev: any) => ({ ...prev, notes: e.target.value }))}}
                                onBlur={handleSaveNotes}
                                fullWidth
                                />
                            </Box>
                        </Stack>
                        <Stack gap={2} sx={{ flexGrow: 1 }}>
                            <Box sx={{ display: "flex", width: "100%", gap: 2, alignItems: "center" }}>
                                <Typography fontWeight={600}>Files:</Typography>
                                <Box sx={{ flexGrow: 1 }} />
                            </Box>
                            <DataGrid
                            hideFooter={true}
                            density="compact"
                            columns={fileColumns}
                            rows={fileRows}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                    pageSize: 100,
                                    },
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                }
                            }}
                            />
                        </Stack>
                    </Box>
                </Paper>
            </Stack>
            <Modal
            open={menu ? true : false}
            onClose={() => {setMenu(false)}}
            >
                {   menu === "view" ?
                    <CheckinViewModal selectedCheckin={selectedCheckin} selectedHorse={selectedHorse} setMenu={setMenu} setUpdateRows={setUpdateRows} />
                    :
                        menu === "change" ?
                        <ChangeDate setSelectedHorse={setSelectedHorse} selectedHorse={selectedHorse} setMenu={setMenu} />
                        :
                        <CheckinCreateModal setSelectedHorse={setSelectedHorse} selectedHorse={selectedHorse} setMenu={setMenu} setUpdateRows={setUpdateRows} />
                }
            </Modal>
        </Container>
    )
}

export default EditHorse