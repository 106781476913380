import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getUser } from "../Api/Admin_api"
import { useUser } from "../Context/User_context"
import { Box, Button, Container, Divider, Paper, Stack, TextField, Typography } from "@mui/material"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { DataGrid } from "@mui/x-data-grid"
import PriorityHighIcon from "@mui/icons-material/PriorityHigh"
import dayjs from "dayjs"

const dateFrequencyMap = (freq: string, updatedAt: Date)  => {
    const map: any = {
        // "Daily": new Date(updatedAt.getDate() + 1),
        // "Bi-Daily": new Date(updatedAt.getDate() + 1),
        // "Weekly": new Date(updatedAt.getDate() + 7),
        // "Bi-Weekly": new Date(updatedAt.getDate() + 4),
        // "Tri-Weekly": new Date(updatedAt.getDate() + 2),
        // "Daily": dayjs(updatedAt).add(1, "day"),
        // "Bi-Daily": dayjs(updatedAt).add(6, "hour"),
        // "Tri-Daily": dayjs(updatedAt).add(4, "hour"),
        "Weekly": dayjs(updatedAt).add(7, "day"),
        "Bi-Weekly": dayjs(updatedAt).add(4, "day"),
        "Tri-Weekly": dayjs(updatedAt).add(2, "day"),
    }
    return map[freq]
}

const Customer = () => {

    const { setNotification, selectedCustomer, setSelectedCustomer } = useUser()
    const { customerId } = useParams()
    const navigate = useNavigate()

    const [requestRows, setRequestRows] = useState([])
    const [visibleRequestRows, setVisibleRequestRows] = useState([])
    const [horseRows, setHorseRows] = useState([])
    const [visibleHorseRows, setVisibleHorseRows] = useState([])
    const [inactiveHorseRows, setInactiveHorseRows] = useState([])
    const [visibleInactiveHorseRows, setVisibleInactiveHorseRows] = useState([])

    useEffect(() => {
        if (customerId) {
            getUser(customerId).then((result: any) => {
                if (result.type === "error") {
                    setNotification({ type: result.type, msg: result.msg })
                } else {
                    setSelectedCustomer(result)
                    setRequestRows(result.serviceRequests)
                    setVisibleRequestRows((result.serviceRequests))

                    const activeHorses = result.Horses.filter((horse: any) => dayjs().isBefore(dayjs(horse.dateLeaving)))
                    const inactiveHorses = result.Horses.filter((horse: any) => dayjs().isAfter(dayjs(horse.dateLeaving)))

                    setHorseRows(activeHorses)
                    setVisibleHorseRows(activeHorses)
                    setInactiveHorseRows(inactiveHorses)
                    setVisibleInactiveHorseRows(inactiveHorses)
                }
            })
        }
    }, [])

    const requestColumns = [
        { field: "id", headerName: "ID" },
        { field: "message", headerName: "Request", flex: 1, renderCell: (params: any) => <>{JSON.parse(params.row.request).name}</> },
        { field: "horseId", headerName: "For Horse", flex: 1, renderCell: (params: any) => <>{selectedCustomer.Horses.find((horse: any) => horse.id === params.row.horseId).name}</> },
        { field: "createdAt", headerName: "Request Date", flex: 1, renderCell: (params: any) => <>{dayjs(params.row.createdAt).format("dddd, MMMM D, YYYY")}</> },
        // {
        //     field: "notif",
        //     headerName: "status",
        //     renderCell: (params: any) => (
        //         params.row.serviceRequests !== 0 ? <PriorityHighIcon color="warning" /> : <></>
        //     )
        // },
    ]

    const horseColumns = [
        { field: "id", headerName: "ID" },
        { field: "name", headerName: "Name", flex: 1 },
        { field: "dateAdmitted", headerName: "Admitted Date", flex: 1, renderCell: (params: any) => <>{dayjs(params.row.dateAdmitted).format("dddd, MMMM D, YYYY")}</> },
        { field: "dateLeaving", headerName: "Tentative Leaving Date", flex: 1, renderCell: (params: any) => <>{dayjs(params.row.dateLeaving).format("dddd, MMMM D, YYYY")}</> },
        {
            field: "notif",
            headerName: "status",
            renderCell: (params: any) => (
                params.row.service && dayjs().isAfter(dateFrequencyMap(params.row.service.notifFrequency, params.row.updatedAt)) ? <PriorityHighIcon color="warning" /> : <></>
            )
        },
    ]

    const inactiveHorseColumns = [
        { field: "id", headerName: "ID" },
        { field: "name", headerName: "Name", flex: 1 },
        { field: "dateAdmitted", headerName: "Admitted", flex: 1, renderCell: (params: any) => <>{dayjs(params.row.dateAdmitted).format("dddd, MMMM D, YYYY")}</> },
        { field: "dateLeaving", headerName: "Left", flex: 1, renderCell: (params: any) => <>{dayjs(params.row.dateLeaving).format("dddd, MMMM D, YYYY")}</> },
    ]

    return (
        <Container sx={{ display: "flex", gap: 2, width: "100%" }}>
            {/* <Stack sx={{ flexGrow: 0.25 }}>
                <Paper sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2, alignItems: "center" }}>
                    <AccountCircleIcon />
                    <Typography>{selectedCustomer.email}</Typography>
                    <Typography>{`${selectedCustomer.fname} ${selectedCustomer.lname}`}</Typography>
                </Paper>
            </Stack> */}
            <Stack sx={{ flexGrow: 1, gap: 1 }}>
                <Paper sx={{ display: "flex", flexDirection: "row", gap: 2, padding: 2, alignItems: "center" }}>
                    {/* <AccountCircleIcon /> */}
                    <Stack gap={1}>
                        <Typography variant="h5">Customer Name: {`${selectedCustomer.fname} ${selectedCustomer.lname}`}</Typography>
                        <Divider />
                        <Typography>{selectedCustomer.email}</Typography>
                    </Stack>
                </Paper>
                {/* <Paper sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2, height: 460 }}>
                    <Box sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography>Service Requests</Typography>
                        <TextField size="small" placeholder="search requests" />
                    </Box>
                    <DataGrid
                    sortModel={[
                        {
                            field: "createdAt",
                            sort: "desc",
                        },
                    ]}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },
                        },
                    }}
                    sx={{ flexGrow: 1 }}
                    getRowClassName={(params: any) => "enabled-row"}
                    columns={requestColumns}
                    rows={visibleRequestRows}
                    onRowClick={(p) => {navigate(`/customer/${p.row.id}`)}}
                    />
                </Paper> */}
                <Paper sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2, height: 980 }}>
                    <Box sx={{ display: "flex", width: "100%", gap: 2, alignItems: "center" }}>
                        <Typography>Active Horses</Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <TextField size="small" placeholder="search horses" />
                        <Button variant="contained" onClick={() => {navigate(`/horse/new/${selectedCustomer.id}`)}}>new horse</Button>
                    </Box>
                    <DataGrid
                    sortModel={[
                        {
                            field: "id",
                            sort: "desc",
                        },
                    ]}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },
                        },
                    }}
                    sx={{ flexGrow: 1 }}
                    getRowClassName={(params: any) => "enabled-row"}
                    columns={horseColumns}
                    rows={visibleHorseRows}
                    onRowClick={(p) => {navigate(`/horse/${selectedCustomer.id}/${p.row.id}`)}}
                    />
                    
                    <Box sx={{ display: "flex", width: "100%", gap: 2, alignItems: "center" }}>
                        <Typography>Previous Horses</Typography>
                        <Box sx={{ flexGrow: 1 }} />
                    </Box>
                    <DataGrid
                    sortModel={[
                        {
                            field: "id",
                            sort: "desc",
                        },
                    ]}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },
                        },
                    }}
                    sx={{ flexGrow: 1 }}
                    getRowClassName={(params: any) => "enabled-row"}
                    columns={inactiveHorseColumns}
                    rows={visibleInactiveHorseRows}
                    onRowClick={(p) => {navigate(`/horse/${selectedCustomer.id}/${p.row.id}`)}}
                    />
                </Paper>
            </Stack>
        </Container>
    )
}

export default Customer