import { Box, Button, Chip, Divider, IconButton, Paper, Stack, TextField, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import dayjs from "dayjs"
import { addCheckin, getCheckin } from "../Api/Admin_api"
import { useUser } from "../Context/User_context"
import { useEffect, useState } from "react"
import CheckIcon from "@mui/icons-material/Check"
import { Download } from "@mui/icons-material"
import { DataGrid } from "@mui/x-data-grid"
import { NumericFormat } from "react-number-format"
import { addService } from "../Api/User_api"

const RequestCheckin = (props: any) => {

    const { setNotification } = useUser()

    const { setMenu, selectedHorse, setSelectedHorse } = props

    const [selectedServices, setSelectedServices] = useState<any>([])
    const [servicePrice, setServicePrice] = useState(0)

    const handleAddRequest = async () => {
        const myRequests = selectedHorse.servicesRequests.filter((service: any) => selectedServices.includes(service.id))
        const result = await addService(selectedHorse.id, myRequests.map((request: any) => {
            const { price, ...other } = request
            return { ...other }
        }))
        if (result.type === "error") {
            setNotification({ type: result.type, msg: result.msg })
        } else {
            setNotification({ type: result.type, msg: result.msg })
            const parsedHorse = {
                ...result.newHorse,
                checkins: selectedHorse.checkins,
                services: result.newHorse.services ? JSON.parse(result.newHorse.services) : [],
                servicesRequests: result.newHorse.servicesRequests ? JSON.parse(result.newHorse.servicesRequests) : [],
                requests: result.newHorse.serviceRequests,
            }
            setSelectedHorse(parsedHorse)
            setMenu(false)
        }
    }

    useEffect(() => {
        setServicePrice(selectedHorse.servicesRequests.filter((service: any) => selectedServices.includes(service.id)).map((service: any) => parseFloat(service.price)).reduce((accumulator: any, currentValue: any) => accumulator + currentValue, 0))
        // selectedHorse.servicesRequests.map((item: any, index: number) => ({
        //     id: index, ...item,
        // })).filter((row: any, index: number) => {
        //     return selectedHorse.servicesRequests.some((request: any) => {
        //         return request.request === JSON.stringify({ name: row.name, frequency: row.frequency })
        //     })
        // })
        console.log(selectedServices)
    }, [selectedServices])

    const columns = [
        { field: "id", headerName: "ID" },
        { field: "name", headerName: "Service", flex: 1 },
        { field: "frequency", headerName: "Frequency of Service", flex: 1 },
        { field: "price", headerName: "Price of Service", flex: 1 },
    ]

    return (
        <Paper sx={{ display: "flex", flexDirection: "column", gap: 2, position: "absolute", width: "60vw", top: "50%", left: "50%", transform: "translate(-50%, -50%)", paddingX: 2, paddingY: 4 }}>
            <Box sx={{ display: "flex", width: "100%" }}>
                <Typography variant="h5">Request Additional Service</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton onClick={() => {setMenu(false)}}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Divider />
            <Stack gap={1}>
                <Typography>
                    Current Active Services:
                </Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                    <Stack>
                        <Typography variant="subtitle2" sx={{ paddingLeft: 1 }}>Included Services:</Typography>
                        <Box
                        sx={{ display: "flex", width: "100%", gap: 1 }}
                        >
                            {selectedHorse?.services.map((field: any) => (
                                <Paper sx={{ borderRadius: 64, display: "flex", gap: 1, alignItems: "center", paddingRight: 2 }}>
                                    <Chip label={field.name} />
                                    <Typography variant="caption">{field.frequency}</Typography>
                                </Paper>
                            ))}
                        </Box>
                    </Stack>
                    {   
                        selectedHorse?.serviceRequests?.length > 0 &&
                        <Stack>
                            <Typography fontStyle={"italic"} variant="subtitle2" sx={{ paddingLeft: 1 }}>Additionally Requested Services:</Typography>
                            <Box
                            sx={{ display: "flex", width: "100%", gap: 1 }}
                            >
                                {selectedHorse.servicesRequests.map((field: any) => (
                                    <Paper sx={{ borderRadius: 64, display: "flex", gap: 1, alignItems: "center", paddingRight: 2, borderColor: "primary.main" }}>
                                        <Chip label={field.name} />
                                        <Typography fontStyle={"italic"} variant="caption">{field.frequency}</Typography>
                                    </Paper>
                                ))}
                            </Box>
                        </Stack>
                    }
                </Box>
            </Stack>
            <Divider />
            <Stack gap={1} sx={{ height: 240 }}>
                <Box sx={{ display: "flex", width: "100%", gap: 1, alignItems: "center" }}>
                    <Typography fontStyle={"italic"}>
                        Available Services to add:
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <NumericFormat
                        displayType={"text"}
                        size="small"
                        value={servicePrice}
                        thousandSeparator={true}
                        prefix={"Additional Cost $ "}
                        placeholder="$ 0.00"
                        customInput={TextField}
                        fixedDecimalScale
                        decimalScale={2}
                        allowNegative={false}
                        allowLeadingZeros={false}
                    />
                </Box>
                <DataGrid
                checkboxSelection
                hideFooter={true}
                density="compact"
                columns={columns}
                rows={selectedHorse.servicesRequests.filter((request: any) => {
                    const parsedRequests = selectedHorse.serviceRequests.map((req: any) => JSON.parse(req.request))
                    return !parsedRequests.some((req: any) => req.id === request.id)
                })}
                getRowClassName={(params: any) => "enabled-row"}
                onRowSelectionModelChange={(selection: any) => {setSelectedServices(selection)}}
                initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 100,
                        },
                    },
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    }
                }}
                />
            </Stack>
            <Box sx={{ display: "flex", width: "100%", gap: 1, alignItems: "center" }}>
                <Box sx={{ flexGrow: 1 }} />
                <Button onClick={handleAddRequest} variant="contained" disabled={selectedServices.length === 0}>
                    {servicePrice !== 0 ? `add services for ${servicePrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} extra` : "add"}
                </Button>
            </Box>
        </Paper>
    )
}

export default RequestCheckin