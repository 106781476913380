import { Box, Button, Container, Divider, Modal, Paper, Stack, TextField, Typography } from "@mui/material"
import { useUser } from "../Context/User_context"
import { DataGrid } from "@mui/x-data-grid"
import PriorityHighIcon from "@mui/icons-material/PriorityHigh"
import { useEffect, useState } from "react"
import { getUsers } from "../Api/Admin_api"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"

const dateFrequencyMap = (freq: string, updatedAt: Date)  => {
    const map: any = {
       "Daily": dayjs(updatedAt).add(1, "day"),
        // "Bi-Daily": new Date(updatedAt.getDate() + 1),
        // "Weekly": new Date(updatedAt.getDate() + 7),
        // "Bi-Weekly": new Date(updatedAt.getDate() + 4),
        // "Tri-Weekly": new Date(updatedAt.getDate() + 2),
        // "Daily": dayjs(updatedAt).add(1, "day"),
        // "Bi-Daily": dayjs(updatedAt).add(6, "hour"),
        // "Tri-Daily": dayjs(updatedAt).add(4, "hour"),
        "Weekly": dayjs(updatedAt).add(7, "day"),
        "Bi-Weekly": dayjs(updatedAt).add(4, "day"),
        "Tri-Weekly": dayjs(updatedAt).add(2, "day"),
    }
    return map[freq]
}

const Admin = () => {

    const { user, setNotification } = useUser()
    const navigate = useNavigate()

    const [cardOpen, setCardOpen] = useState(false)
    const [customerSearchInput, setCustomerSearchInput] = useState("")
    const [adminSearchInput, setAdminSearchInput] = useState("")

    const columns = [
        { field: "id", headerName: "ID" },
        { field: "email", headerName: "Email", flex: 1 },
        { field: "fname", headerName: "First Name", flex: 1 },
        { field: "lname", headerName: "Last Name", flex: 1 },
        { field: "phoneNumber", headerName: "Phone Number", flex: 1, renderCell: (params: any) => <>{params.row.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}</> },
        {
            field: "horseCount",
            headerName: "# of Horses",
            flex: 1,
            renderCell: (params: any) => (
                <Typography>{params.row.horses !== 0 ? params.row.horses : 0}</Typography>
            )
        },
        {
            field: "notif",
            headerName: "status",
            renderCell: (params: any) => (
                params.row.service && dayjs().isAfter(dateFrequencyMap(params.row.service.notifFrequency, params.row.updatedAt)) ? <PriorityHighIcon color="warning" /> : <></>
            )
        },
    ]

    const adminColumns = [
        { field: "id", headerName: "ID" },
        { field: "email", headerName: "Email", flex: 1 },
        { field: "fname", headerName: "First Name", flex: 1 },
        { field: "lname", headerName: "Last Name", flex: 1 },
    ]

    const [rows, setRows] = useState([])
    const [visibleRows, setVisibleRows] = useState([])
    const [adminRows, setAdminRows] = useState([])
    const [visibleAdminRows, setVisibleAdminRows] = useState([])

    useEffect(() => {
        getUsers().then((result: any) => {
            if (result.type === "error") {
                setNotification({ type: result.type, msg: result.msg })
            } else {
                setRows(result.activeUsers)
                setVisibleRows(result.activeUsers)
                setAdminRows(result.admins)
                setVisibleAdminRows(result.admins)
            }
        })
    }, [])

    useEffect(() => {
        if (customerSearchInput !== "") {
            setVisibleRows(rows.filter((row: any) => Object.values(row).toString().toLowerCase().includes(customerSearchInput)))
        } else {
            setVisibleRows(rows)
        }
        if (adminSearchInput !== "") {
            setVisibleAdminRows(rows.filter((row: any) => Object.values(row).toString().toLowerCase().includes(adminSearchInput)))
        } else {
            setVisibleAdminRows(rows)
        }
    }, [customerSearchInput, adminSearchInput])

    return (
        <Container maxWidth="xl" sx={{ display: "flex", height: "100%", flexDirection: "column", gap: 2 }}>
            <Typography variant="h4">Welcome, {user?.fname}</Typography>
            <Divider />
            <Stack gap={1} sx={{ flexGrow: 1 }}>
                <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "flex-end" }}>
                    <Typography variant="h5">Active Customers</Typography>
                    <Box sx={{ display: "flex", gap: 1 }}>
                        <TextField size="small" placeholder="Search" value={customerSearchInput} onChange={(e: any) => {setCustomerSearchInput(e.target.value)}} />
                        <Button onClick={() => {navigate("/customer/new")}} startIcon={<AddCircleIcon />} size="small" variant="contained">new customer</Button>
                    </Box>
                </Box>
                <DataGrid
                sx={{ flexGrow: 1 }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                getRowClassName={(params: any) => "enabled-row"}
                columns={columns}
                rows={visibleRows}
                onRowClick={(p) => {navigate(`/customer/${p.row.id}`)}}
                />
            </Stack>
            <Divider />
            <Stack gap={1} sx={{ flexGrow: 1 }}>
                <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "flex-end" }}>
                    <Typography variant="h5">Admin Users</Typography>
                    <Box sx={{ display: "flex", gap: 1 }}>
                        <TextField size="small" placeholder="Search" value={adminSearchInput} onChange={(e: any) => {setAdminSearchInput(e.target.value)}} />
                        <Button onClick={() => {navigate("/admin/new")}} startIcon={<AddCircleIcon />} size="small" variant="contained">new admin</Button>
                    </Box>
                </Box>
                <DataGrid
                sx={{ flexGrow: 1 }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                columns={adminColumns}
                rows={visibleAdminRows}
                />
            </Stack>
            <Modal
            open={cardOpen}
            onClose={() => {setCardOpen(false)}}
            >
                <Paper>
                    
                </Paper>
            </Modal>
        </Container>
    )
}

export default Admin