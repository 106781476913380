import { PropsWithChildren, createContext, useContext, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { auth, login as loginAPI, logout as logoutAPI, register } from "../Api/User_api"

interface IValue {
    user: IUser | undefined | null,
    setNotification: (newNotification: INotification | undefined) => void,
    //<any>??
    login: (email: string, password: string) => Promise<any>,
    register: (fname: string, lname: string, password: string, token: string) => Promise<any>,
    logout: () => Promise<any>,
    notification?: INotification,
    setSelectedCustomer: (val: any) => void
    selectedCustomer: any,
}

interface INotification {
    type: string,
    msg: string,
}

export interface IUser {
    id: number,
    email: string,
    uname: string,
    fname: string,
    lname: string,
    status: number,
}

const defaultUser = {
    user: undefined,
    //bruh.
    setNotification: () => {},
    login: loginAPI,
    logout: logoutAPI,
    register: register,
    refreshInfo: auth,
    setSelectedCustomer: () => {},
    selectedCustomer: {},
}

const UserContext = createContext<IValue>(defaultUser)

export const useUser = () => (
    useContext(UserContext)
)

const UserProvider = (props: PropsWithChildren) => {

    const [notification, setNotificationInternal] = useState<INotification | undefined>()
    const [user, setUserInternal] = useState<IUser | null | undefined>()

    const [selectedCustomer, setSelectedCustomerInternal] = useState({})

    const setUser = (newUser: IUser) => {
        setUserInternal(newUser)
    }

    const setSelectedCustomer = (val: any) => {
        setSelectedCustomerInternal(val)
    }

    const setNotification = (newNotification: INotification | undefined) => {
        setNotificationInternal(newNotification)
    }

    const login: any = async (email: string, password: string) => {
        const result = await loginAPI(email, password)
        if (result.type !== "error") {
            setUserInternal(result)
        }
        return result
    }
    
    const logout: any = async () => {
        const result = await logoutAPI()
        if (result.type === "success") {
            setUserInternal(null)
        }
        return result
    }

    useEffect(() => {
        auth().then(result => {
            if (result.type !== "error") {
                setUserInternal(result)
            } else {
                setUserInternal(null)
            }
        })
    },[])

    return (
        <UserContext.Provider value={{ user, login, logout, register, notification, setNotification, setSelectedCustomer, selectedCustomer }}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserProvider