import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { addService, getService } from "../Api/Admin_api"
import { Autocomplete, Box, Button, Container, Divider, IconButton, Paper, Stack, TextField, Typography } from "@mui/material"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import DeleteIcon from "@mui/icons-material/Delete"
import EditableItem from "../Components/EditableItem"
import { useUser } from "../Context/User_context"
import { NumericFormat } from "react-number-format"

const Service = () => {

    const navigate = useNavigate()

    const { serviceId } = useParams()
    const { setNotification } = useUser()

    const [serviceInfo, setServiceInfo] = useState<any>({
        id: serviceId,
        price: 0,
    })

    const [frequency, setFrequency] = useState([
        "Daily",
        "Bi-Daily",
        "Tri-Daily",
        "Weekly",
    ])

    const handleNewService = () => {
        setServiceInfo((prev: any) => ({
            ...prev,
            fields: [ ...(prev.fields ?? []), { name: `New Field ${prev.fields ? prev.fields.length + 1 : 1}`, frequency: "Daily" } ]
        }))
    }

    const handleDeleteService = (id: number) => {
        setServiceInfo((prev: any) => ({
            ...prev,
            fields: prev.fields.filter((field: any, index: number) => index !== id)
            // fields: [ ...prev.fields.slice(0, id), ...prev.fields.slice(id + 1) ]
        }))
    }

    const handleNewRequest = () => {
        setServiceInfo((prev: any) => ({
            ...prev,
            requests: [ ...(prev.requests ?? []), { name: `New Field ${prev.requests ? prev.requests.length + 1 : 1}`, frequency: "Daily" } ]
        }))
    }

    const handleDeleteRequest = (id: number) => {
        setServiceInfo((prev: any) => ({
            ...prev,
            requests: prev.requests.filter((field: any, index: number) => index !== id)
        }))
    }

    const handleCreateService = async () => {
        const result = await addService({
            ...serviceInfo,
            price: serviceInfo.price.replace(/[^\d.]/g, ""),
        })
        setNotification({ type: result.type, msg: result.msg })
        if (result.type !== "error") {
            navigate("/services")
        }
    }

    useEffect(() => {
        if (serviceId) {
            getService(parseInt(serviceId)).then((result: any) => {
                if (result.type === "error") {
                    setNotification({ type: result.type, msg: result.msg })
                } else {
                    setServiceInfo({
                        ...result,
                        price: `${result.price}`,
                        fields: JSON.parse(result.fields),
                        requests: JSON.parse(result.requestables),
                    })
                }
            })
        }
    }, [])

    return (
        <Container sx={{ display: "flex", gap: 2, width: "100%" }}>
            <Stack gap={3} sx={{ flexGrow: 1 }}>
                <Typography variant="h4">{serviceInfo.id ? "Update Service Package" : "Create Service Package" }</Typography>
                <Divider />
                <Box sx={{ width: "100%", display: "flex", gap: 1, justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ paddingLeft: 1 }}>Package Name</Typography>
                    <TextField
                    required
                    placeholder={`Enter Service Name`}
                    value={serviceInfo?.name ?? ""}
                    onChange={(e) => {setServiceInfo((prev: any) => ({ ...prev, name: e.target.value }))}}
                    size="small"
                    sx={{ width: "100%" }}
                    />
                </Box>
                <Box sx={{ width: "100%", display: "flex", gap: 1, justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ paddingLeft: 1 }}>Service Check-in frequency</Typography>
                    <Autocomplete
                    size="small"
                    sx={{ width: "100%" }}
                    value={serviceInfo.notifFrequency ?? "Weekly"}
                    options={frequency}
                    onChange={(e: any, v: any) => setServiceInfo((prev: any) => ({ ...prev, notifFrequency: v }))}
                    renderInput={(params: any) => <TextField {...params} label="Frequency" />}
                    />
                </Box>
                <Box sx={{ width: "100%", display: "flex", gap: 1, justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ paddingLeft: 1 }}>Service Price</Typography>
                    <NumericFormat
                        fullWidth
                        size="small"
                        value={serviceInfo.price}
                        thousandSeparator={true}
                        prefix={"$ "}
                        placeholder="$ 0.00"
                        customInput={TextField}
                        fixedDecimalScale
                        decimalScale={2}
                        onChange={(e: any) => {setServiceInfo((prev: any) => ({ ...prev, price: e.target.value }))}}
                        allowNegative={false}
                        allowLeadingZeros={false}
                        isAllowed={(values) => {
                            const {floatValue} = values
                            return (floatValue ?? 0) >= 0
                        }}
                    />
                </Box>
                <Box sx={{ width: "100%", display: "flex", gap: 1, justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ paddingLeft: 1 }}>Included in package</Typography>
                    <Stack sx={{ width: "100%", gap: 2 }}>
                        {serviceInfo.fields && serviceInfo.fields.length > 0 && serviceInfo.fields.map((field: any, index: number) => (
                            <Paper sx={{ width: "100%", display: "flex", gap: 1, justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column", padding: 2 }}>
                                <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", paddingLeft: 1 }}>
                                    <EditableItem onBlur={(v: any) => {setServiceInfo((prev: any) => ({ ...prev, fields: prev.fields.map((field: any, i: number) => i === index ? {...field, name: v } : field)}))}}>
                                        {field.name}
                                    </EditableItem>
                                    <IconButton onClick={() => {handleDeleteService(index)}} color="error">
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
                                    {/* <TextField
                                    fullWidth
                                    label="Service Type"
                                    placeholder={`Enter Service Type`}
                                    value={serviceInfo.fields[index].type}
                                    onChange={(e) => {setServiceInfo((prev: any) => ({ ...prev, fields: prev.fields.map((field: any, i: number) => i === index ? {...field, type: e.target.value } : field)}))}}
                                    size="small"
                                    sx={{ width: "100%" }}
                                    /> */}
                                    <Autocomplete
                                    size="small"
                                    sx={{ width: "100%" }}
                                    value={field.frequency ?? "Daily"}
                                    options={frequency}
                                    onChange={(e: any, v: any) => {setServiceInfo((prev: any) => ({ ...prev, fields: prev.fields.map((field: any, i: number) => i === index ? {...field, frequency: v } : field)}))}}
                                    renderInput={(params: any) => <TextField {...params} label="Frequency" />}
                                    />
                                </Box>
                            </Paper>
                        ))}
                    </Stack>
                    <Box sx={{ display: "flex", width: "100%" }}>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton onClick={handleNewService} color="primary" sx={{ borderRadius: 20 }}>
                            <AddCircleIcon sx={{ fontSize: "2em" }} />
                        </IconButton>
                    </Box>
                </Box>
                <Divider />
                <Box sx={{ width: "100%", display: "flex", gap: 1, justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ paddingLeft: 1, fontStyle: "italic" }}>Requestable services</Typography>
                    <Stack sx={{ width: "100%", gap: 2 }}>
                        {serviceInfo.requests && serviceInfo.requests.length > 0 && serviceInfo.requests.map((field: any, index: number) => (
                            <Paper sx={{ width: "100%", display: "flex", gap: 1, justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column", padding: 2 }}>
                                <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", paddingLeft: 1 }}>
                                <EditableItem onBlur={(v: any) => {setServiceInfo((prev: any) => ({ ...prev, requests: prev.requests.map((field: any, i: number) => i === index ? {...field, name: v } : field)}))}}>
                                        {field.name}
                                    </EditableItem>
                                    <IconButton onClick={() => {handleDeleteRequest(index)}} color="error">
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
                                    {/* <TextField
                                    fullWidth
                                    label="Service Type"
                                    placeholder={`Enter Service Type`}
                                    value={serviceInfo.fields[index].type}
                                    onChange={(e) => {setServiceInfo((prev: any) => ({ ...prev, fields: prev.fields.map((field: any, i: number) => i === index ? {...field, type: e.target.value } : field)}))}}
                                    size="small"
                                    sx={{ width: "100%" }}
                                    /> */}
                                    <Autocomplete
                                    size="small"
                                    sx={{ width: "100%" }}
                                    value={field.frequency ?? "Daily"}
                                    options={frequency}
                                    onChange={(e: any, v: any) => {setServiceInfo((prev: any) => ({ ...prev, requests: prev.requests.map((field: any, i: number) => i === index ? {...field, frequency: v } : field)}))}}
                                    renderInput={(params: any) => <TextField {...params} label="Frequency" />}
                                    />
                                    <NumericFormat
                                        fullWidth
                                        size="small"
                                        value={field.price}
                                        thousandSeparator={true}
                                        prefix={"$ "}
                                        placeholder="$ 0.00"
                                        customInput={TextField}
                                        fixedDecimalScale
                                        decimalScale={2}
                                        onChange={(e: any) => {setServiceInfo((prev: any) => ({ ...prev, requests: prev.requests.map((field: any, i: number) => i === index ? {...field, price: e.target.value } : field)}))}}
                                        allowNegative={false}
                                        allowLeadingZeros={false}
                                        isAllowed={(values) => {
                                            const {floatValue} = values
                                            return (floatValue ?? 0) >= 0
                                        }}
                                    />
                                </Box>
                            </Paper>
                        ))}
                    </Stack>
                    <Box sx={{ display: "flex", width: "100%" }}>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton onClick={handleNewRequest} color="primary" sx={{ borderRadius: 20 }}>
                            <AddCircleIcon sx={{ fontSize: "2em" }} />
                        </IconButton>
                    </Box>
                </Box>
                <Divider />
                <Button onClick={handleCreateService} variant="contained">{serviceInfo.id ? "Update Service Package" : "Create Service Package" }</Button>
            </Stack>
        </Container>
    )
}

export default Service