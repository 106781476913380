import { Box, Button, Chip, Container, Divider, IconButton, Modal, Paper, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { getUser } from "../Api/User_api"
import { useUser } from "../Context/User_context"
import { useNavigate, useParams } from "react-router-dom"
import DownloadIcon from "@mui/icons-material/Download"
import { DataGrid } from "@mui/x-data-grid"
import dayjs from "dayjs"
import CheckinViewModal from "../Components/CheckinView"
import RequestCheckin from "../Components/RequestCheckin"
import AirlineStopsIcon from "@mui/icons-material/AirlineStops"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"

const dateFrequencyMap = (freq: string, updatedAt: Date)  => {
    const map: any = {
        "Daily": dayjs(updatedAt).add(1, "day"),
        // "Bi-Daily": new Date(updatedAt.getDate() + 1),
        // "Weekly": new Date(updatedAt.getDate() + 7),
        // "Bi-Weekly": new Date(updatedAt.getDate() + 4),
        // "Tri-Weekly": new Date(updatedAt.getDate() + 2),
        // "Daily": dayjs(updatedAt).add(1, "day"),
        // "Bi-Daily": dayjs(updatedAt).add(6, "hour"),
        // "Tri-Daily": dayjs(updatedAt).add(4, "hour"),
        "Weekly": dayjs(updatedAt).add(7, "day"),
        "Bi-Weekly": dayjs(updatedAt).add(4, "day"),
        "Tri-Weekly": dayjs(updatedAt).add(2, "day"),
    }
    return map[freq]
}

const ViewHorse = () => {

    const { user, selectedCustomer, setNotification } = useUser()
    const navigate = useNavigate()

    const { horseId } = useParams()

    const [selectedHorse, setSelectedHorse] = useState<any>({
        name: "",
        updatedAt: "",
        services: [],
        servicesRequests: [],
        requests: [],
        checkins: [],
    })
    const [selectedCheckin, setSelectedCheckin] = useState({})

    const [menu, setMenu] = useState<boolean | string>(false)

    const [rows, setRows] = useState([])

    const handleDownload = async (link: string, fileName: string) => {
        try {
            const response = await fetch(link);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    useEffect(() => {
        if (!selectedCustomer.Horses) {
            getUser().then((result: any) => {
                if (result.type === "error") {
                    setNotification({ type: result.type, msg: result.msg })
                } else {
                    const parsedRows = result.Horses.map((horse: any) => {
                        const { checkins, ...other } = horse
                        return { ...other, checkins, files: result.files[0].files , images: result.files[0].images }
                    })
                    const myHorse = parsedRows.find((horse: any) => horse.id === parseInt(horseId ?? "-1"))
                    setImages(myHorse.images.map((item: any) => ({
                        original: `${item.dir}?type=image`,
                        thumbnail: `${item.dir}?type=image`,
                    })))
                    const parsedHorse = {
                        ...myHorse,
                        services: myHorse.services ? JSON.parse(myHorse.services) : [],
                        servicesRequests: myHorse.servicesRequests ? JSON.parse(myHorse.servicesRequests) : [],
                        requests: myHorse.serviceRequests,
                        checkins: result.checkins[0].filter((check: any) => check.HorseId === myHorse.id),
                    }
                    setRows(myHorse.files.map((file: any, index: number) => ({ ...file, id: index })))
                    setSelectedHorse(parsedHorse)
                }
            })
        } else {
            // setSelectedHorse(selectedCustomer.Horses.find((horse: any) => horse.id === parseInt(horseId ?? "-1")))
        }
    }, [])

    const [images, setImages] = useState([])

    const updateColumns = [
        { field: "id", headerName: "ID" },
        { field: "note", headerName: "Note", flex: 1 },
        { field: "createdAt", headerName: "Recieved At", flex: 1, renderCell: (params: any) => <>{dayjs(params.row.createdAt).format("dddd, MMMM D, YYYY")}</> },
    ]

    const fileColumns = [
        { field: "id", headerName: "ID" },
        { field: "name", headerName: "File Name", flex: 1 },
        { field: "id", headerName: "", flex: 1, renderCell: (params: any) => <IconButton><DownloadIcon /></IconButton> },
        // { field: "createdAt", headerName: "Uploaded At", flex: 1, renderCell: (params: any) => <>{dayjs(params.row.createdAt).format("dddd, MMMM D, YYYY")}</> },
        { field: "createdAt", headerName: "", flex: 0.1, renderCell: (params: any) => <IconButton onClick={() => {handleDownload(`${params.row.dir}?type=file`, params.row.name)}}><DownloadIcon /></IconButton> },
    ]

    return (
        <Container sx={{ display: "flex", gap: 2, width: "100%" }}>
            <Stack gap={3} sx={{ flexGrow: 1 }}>
                <Stack gap={3} sx={{ flexDirection: "row", height: 360, alignItems: "flex-end" }}>
                    {/* <Box sx={{ height: "100%", width: 360, backgroundColor: "green" }} /> */}
                    <Stack>
                        <ImageGallery items={images} additionalClass={"gallery"} />
                    </Stack>
                    <Stack gap={1} sx={{ flexGrow: 1, alignItems: "flex-start" }}>
                        <Typography variant="h4">{selectedHorse.name}</Typography>
                        <Divider sx={{ width: "100%" }} />
                        <Paper sx={{ borderRadius: 64, paddingX: 1, paddingY: 0.5 }}>
                            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                                <Stack>
                                    <Typography variant="caption">
                                        Arrived {dayjs(selectedHorse.dateAdmitted).format("dddd, MMMM D, YYYY")}
                                    </Typography>
                                </Stack>
                                <AirlineStopsIcon sx={{ fontSize: "1.2rem" }} />
                                <Stack>
                                    <Typography variant="caption">
                                        Tentative leave scheduled for {dayjs(selectedHorse.dateLeaving).format("dddd, MMMM D, YYYY")}
                                    </Typography>
                                </Stack>
                            </Box>
                        </Paper>
                        {/* <Typography variant="h6">Next update scheduled for {dayjs(dateFrequencyMap(selectedHorse.services.notifFrequency, selectedHorse.updatedAt)).format("dddd, MMMM D, YYYY - h:mm A")}</Typography>
                        <Typography>Last Update was {dayjs(selectedHorse.updatedAt).format("dddd, MMMM D, YYYY - h:mm A")}</Typography> */}
                        <Stack sx={{ paddingTop: 2, gap: 1 }}>
                            <Typography variant="h6">{selectedHorse.name}'s Service Package: <b>{selectedHorse.services.name}</b></Typography>
                            <Box sx={{ display: "flex", gap: 1 }}>
                                <Stack>
                                    <Typography variant="subtitle2" sx={{ paddingLeft: 1 }}>Included Services:</Typography>
                                    <Box
                                    sx={{ display: "flex", width: "100%", gap: 1 }}
                                    >
                                        {selectedHorse.services.map((field: any) => (
                                            <Paper sx={{ borderRadius: 64, display: "flex", gap: 1, alignItems: "center", paddingRight: 2 }}>
                                                <Chip label={field.name} />
                                                <Typography variant="caption">{field.frequency}</Typography>
                                            </Paper>
                                        ))}
                                    </Box>
                                </Stack>
                                {   
                                    selectedHorse?.requests?.length > 0 &&
                                    <Stack>
                                        <Typography fontStyle={"italic"} variant="subtitle2" sx={{ paddingLeft: 1 }}>Additionally Requested Services:</Typography>
                                        <Box
                                        sx={{ display: "flex", width: "100%", gap: 1 }}
                                        >
                                            {selectedHorse.requests.map((field: any) => (
                                                <Paper sx={{ borderRadius: 64, display: "flex", gap: 1, alignItems: "center", paddingRight: 2, borderColor: "primary.main" }}>
                                                    <Chip label={JSON.parse(field.request).name} />
                                                    <Typography fontStyle={"italic"} variant="caption">{JSON.parse(field.request).frequency}</Typography>
                                                </Paper>
                                            ))}
                                        </Box>
                                    </Stack>
                                }
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>
                <Paper sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2, height: 460 }}>
                    <Box sx={{ display: "flex", width: "100%", gap: 2, alignItems: "center" }}>
                        <Typography variant="h5">Updates:</Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Button onClick={() => {setMenu("request")}} variant="contained">request additional service</Button>
                    </Box>
                    <DataGrid
                    hideFooter={true}
                    density="compact"
                    columns={updateColumns}
                    rows={selectedHorse.checkins}
                    getRowClassName={(params: any) => "enabled-row"}
                    initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 100,
                            },
                        },
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },
                        }
                    }}
                    sortModel={[
                        {
                            field: "createdAt",
                            sort: "desc",
                        },
                    ]}
                    onRowClick={(p) => {setSelectedCheckin(p.row); console.log(p.row); setMenu("view")}}
                    />
                </Paper>
                <Paper sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2, height: 460 }}>
                    <Box sx={{ display: "flex", width: "100%", gap: 2, alignItems: "center" }}>
                        <Typography variant="h5">Documents:</Typography>
                        <Box sx={{ flexGrow: 1 }} />
                    </Box>
                    <DataGrid
                    hideFooter={true}
                    density="compact"
                    columns={fileColumns}
                    rows={rows}
                    getRowClassName={(params: any) => "enabled-row"}
                    initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 100,
                            },
                        },
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },
                        }
                    }}
                    sortModel={[
                        {
                            field: "createdAt",
                            sort: "desc",
                        },
                    ]}
                    />
                </Paper>
            </Stack>
            <Modal
            open={menu ? true : false}
            onClose={() => {setMenu(false)}}
            >
                {   menu === "view" ?
                    <CheckinViewModal selectedCheckin={selectedCheckin} selectedHorse={selectedHorse} setMenu={setMenu} />
                    :
                    <RequestCheckin selectedCheckin={selectedCheckin} selectedHorse={selectedHorse} setMenu={setMenu} setSelectedHorse={setSelectedHorse} />
                }
            </Modal>
        </Container>
    )
}

export default ViewHorse