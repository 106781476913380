import { Box, Button, Divider, IconButton, Paper, Stack, Typography } from "@mui/material"
import dayjs from "dayjs"
import CloseIcon from "@mui/icons-material/Close"
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useState } from "react"
import { updateHorse } from "../Api/Admin_api"
import { useUser } from "../Context/User_context"

const ChangeDate = (props: any) => {

    const { setMenu, selectedHorse, setSelectedHorse } = props

    const { setNotification } = useUser()
    const [date, setDate] = useState(dayjs(selectedHorse.dateLeaving))

    const handleUpdate = async () => {
        const result = await updateHorse({ dateLeaving: date }, selectedHorse.id)
        setNotification({ type: result.type, msg: result.msg })
        if (result.type === "error") {
            setMenu(null)
        } else {
            setSelectedHorse({
                ...selectedHorse,
                dateLeaving: result.horse.dateLeaving,
            })
            setMenu(null)
        }
    }

    return (
        <Paper sx={{ display: "flex", flexDirection: "column", gap: 2, position: "absolute", width: "60vw", top: "50%", left: "50%", transform: "translate(-50%, -50%)", paddingX: 2, paddingY: 4 }}>
            <Box sx={{ display: "flex", width: "100%" }}>
                <Typography variant="h5">Change Tentative Leave Date</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton onClick={() => {setMenu(false)}}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Divider />
            <Typography>
                <b>Current Scheduled Tentative Leaving Date:</b> {dayjs(selectedHorse.dateLeaving).format("dddd, MMMM D, YYYY - h:mm A")}
            </Typography>
            <Typography>
                <b>New Scheduled Tentative Leaving Date:</b> {date.format("dddd, MMMM D, YYYY - h:mm A")}
            </Typography>
            <Stack gap={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                    value={date}
                    onChange={(e: any) => {setDate(e)}}
                    sx={{ width: "100%" }}
                    />
                </LocalizationProvider>
            </Stack>
            <Box sx={{ display: "flex", width: "100%" }}>
                <Box sx={{ flexGrow: 1 }} />
                <Button
                variant="contained"
                onClick={handleUpdate}
                >
                    update tentative leave date
                </Button>
            </Box>
        </Paper>
    )
}

export default ChangeDate