import { Alert, AlertColor, Box, CssBaseline, LinearProgress, Snackbar, Theme, ThemeProvider, Typography, createTheme, useMediaQuery } from "@mui/material"
import { Container } from "@mui/system"
import { PropsWithChildren, useEffect, useState } from "react"
import { Outlet, useLocation } from 'react-router-dom'
import Header from "../Components/Header"
import { useUser } from "../Context/User_context"

const Layout = (props: PropsWithChildren) => {

    const { notification, setNotification } = useUser()
    const location = useLocation()

    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: light)")

    const darkPalette = {
      primary: {
        light: "#fff8dd",
        main: "#072a44",
        dark: "#97792d",
      },
      secondary: {
        light: "#9f8f6e",
        main: "#2e2e2e",
        dark: "#6e6c6c",
      },
      tertiary: {
        main: "#303032",
      },
      outline: {
        main: "#1e1b16"
      },
      background: {
        default: "#161618",
      },
      surface: {
        main: "#212124",
      }
    }

    const lightPalette = {
      primary: {
        light: "#b88a00",
        main: "#072a44",
        dark: "#4c3901"
      },
      secondary: {
        light: "#a38e60",
        main: "#6b5d3f",
        dark: "#362f20",
      },
      tertiary: {
        main: "#e8e4e4",
      },
      outline: {
        main: "#f8f2f2"
      },
      background: {
        default: "#fffbff",
      },
      surface: {
        main: "#f8f2f2",
      }
    }

    const theme = createTheme({
      palette: {
        mode: prefersDarkMode ? "dark" : "light",
        ...(prefersDarkMode ?
              darkPalette
              :
              lightPalette
          )
      },
      components: {
        // Name of the component
        MuiButton: {
          styleOverrides: {
            root: {
             borderRadius: '8px'
            },
          },
        },
        MuiButtonBase: {
          styleOverrides: {
            root: {
             borderRadius: '8px'
            },
          },
        },
        MuiPaper: {
          defaultProps: {
            variant: "outlined",
          },
          styleOverrides: {
            root: {
              borderRadius: "8px",
              // borderColor: prefersDarkMode ? darkPalette.outline.main : lightPalette.outline.main,
              variant: "outlined",
              backgroundColor: prefersDarkMode ? darkPalette.surface.main : lightPalette.surface.main
            }
          }
        },
        MuiMenu: {
          styleOverrides: {
            paper: {
              borderRadius: "8px",
            }
          }
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: '8px'
            }
          }
        },
      },
    })

    const [progress, setProgress] = useState<number>(0)

    useEffect(() => {
        if (notification) {
            setProgress(100)
        }
    }, [notification])

    useEffect(() => {
        if (progress) {
            const timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress - 25))
            }, 800)
    
            if (progress <= 0) {
                clearInterval(timer)
            }
    
            return () => {
            clearInterval(timer)
            }
        }
    }, [progress])

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [location])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Header />
            <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "100vw", paddingY: 12 }}>
                {/* <Sidebar /> */}
                <Outlet />
            </Box>
            <Snackbar
            open={Boolean(notification)}
            autoHideDuration={4000}
            onClose={() => {setNotification(undefined)}}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Box>
                    <Alert
                    sx={{ width: { xs: "87vw", md: "400px" } }}
                    severity={notification?.type as AlertColor}
                    >
                        <Typography
                        variant='caption'
                        >
                            {notification?.msg}
                        </Typography>
                        <LinearProgress
                        variant='determinate'
                        value={progress}
                        sx={{ width: '360px' }}
                        />
                    </Alert>
                </Box>
            </Snackbar>
        </ThemeProvider>
    )
}

export default Layout