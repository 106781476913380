import { Box, Button, Container, Divider, Paper, Popover, Stack, TextField, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import { getSettings, updateSettings } from "../Api/Admin_api"
import { useUser } from "../Context/User_context"

const Settings = () => {

    const { setNotification } = useUser()
    const [selectedFacilities, setSelectedFacilities] = useState<any>([])

    const [newFacility, setNewFacility] = useState("")

    const [settings, setSettings] = useState<any>({
        notifEmail: "",
        facilities: [],
    })

    const [anchorEl, setAnchorEl] = useState<any>(null)

    const columns = [
        { field: "id", headerName: "ID" },
        { field: "name", headerName: "Facility Name", flex: 1 },
    ]

    const handleUpdateSettings = async (passedSettings: any) => {
        const internalSettings = passedSettings ?? settings
        const result = await updateSettings({
            ...internalSettings,
            facilities: newFacility !== "" ? [...internalSettings.facilities, newFacility] : internalSettings.facilities
        })
        if (result.type !== "error") {
            setSettings({
                notifEmail: result.notifEmail,
                facilities: result.facilities,
            })
            setAnchorEl(false)
            setNewFacility("")
        } else {
            setNotification({ type: result.type, msg: result.msg })
        }
    }

    useEffect(() => {
        getSettings().then((result: any) => {
            if (result.type === "error") {
                setNotification({ type: result.type, msg: result.msg })
            } else {
                setSettings({
                    notifEmail: result.notifEmail,
                    facilities: JSON.parse(result.facilities),
                })
            }
            // console.log(JSON.parse(result.facilities).map((facility: any, index: number) => ({ id: index, name: facility })))
        })
    }, [])

    return (
        <Container sx={{ display: "flex", gap: 2, width: "100%" }}>
            <Stack gap={3} sx={{ flexGrow: 1 }}>
                <Typography variant="h4">Settings</Typography>
                <Divider />
                <Stack sx={{ minHeight: 480 }} gap={2}>
                    <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
                        <Typography variant="h5">
                            Facilities
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Button
                        variant="contained"
                        onClick={(e: any) => {setAnchorEl(e.currentTarget)}}
                        >
                            add
                        </Button>
                        <Button
                        disabled={selectedFacilities.length === 0}
                        variant="contained"
                        onClick={() => {handleUpdateSettings({
                            ...settings,
                            facilities: settings.facilities.filter((facility: string, index: number) => !selectedFacilities.includes(index))
                            })
                        }}
                        >
                            delete
                        </Button>
                    </Box>
                    <DataGrid
                    checkboxSelection
                    onRowSelectionModelChange={(selection: any) => {setSelectedFacilities(selection)}}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },
                        },
                    }}
                    columns={columns}
                    rows={settings.facilities.map((facility: any, index: number) => ({ id: index, name: facility }))}
                    />
                    <Divider />
                    <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
                        <Typography variant="h5">
                            Notification Email
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                    </Box>
                    <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
                        <TextField
                        sx={{ flexGrow: 1 }}
                        size="small"
                        placeholder="Email Address"
                        value={settings.notifEmail}
                        onChange={(e: any) => {setSettings((prev: any) => ({
                            ...prev,
                            notifEmail: e.target.value
                        }))}}
                        />
                        <Button
                        size="small"
                        variant="contained"
                        onClick={() => {handleUpdateSettings(null)}}
                        >
                            Update Notification Email
                        </Button>
                    </Box>
                </Stack>
            </Stack>
            <Popover
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={() => {setAnchorEl(null)}}
            open={anchorEl ? true : false}
            anchorEl={anchorEl}
            >
                <Paper sx={{ display: "flex", flexDirection: "column", gap: 1, padding: 1 }}>
                    <TextField
                    placeholder="Facility Name"
                    size="small"
                    // onChange={(e: any) => {setSettings((prev: any) => ({
                    //     ...prev,
                    //     notifEmail: e.target.value
                    // }))}}
                    onChange={(e: any) => {setNewFacility(e.target.value)}}
                    value={newFacility}
                    />
                    <Button onClick={() => {handleUpdateSettings(null)}}>add</Button>
                </Paper>
            </Popover>
        </Container>
    )
}

export default Settings