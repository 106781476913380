import "./global.css"

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import UserProvider from "./Context/User_context"
import ProtectedRoute from "./Components/ProtectedRoute"
import PublicRoute from "./Components/PublicRoute"
import Layout from "./Pages/Layout"
import Login from "./Pages/Login"
import Home from "./Pages/Home"
import AdminRoute from "./Components/AdminRoute"
import Admin from "./Pages/Admin"
import Customer from "./Pages/Customer"
import CreateHorse from "./Pages/CreateHorse"
import EditHorse from "./Pages/EditHorse"
import CreateUser from "./Pages/CreateUser"
import Services from "./Pages/Services"
import Audits from "./Pages/Audits"
import Settings from "./Pages/Settings"
import Customers from "./Pages/Customers"
import Service from "./Pages/Service"
import ViewHorse from "./Pages/ViewHorse"
import Register from "./Pages/Register"

function App() {
  return (
    <UserProvider>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={ <Layout /> }>
              <Route element={ <ProtectedRoute /> }>
                <Route element={ <AdminRoute /> }>
                  <Route path="/admin" element={ <Admin /> } />
                  <Route path="/customers" element={ <Customers /> } />
                  <Route path="/settings" element={ <Settings /> } />
                  <Route path="/audits" element={ <Audits /> } />
                  <Route path="/services" element={ <Services /> } />
                  <Route path="/services/:serviceId" element={ <Service /> } />
                  <Route path="/services/new" element={ <Service /> } />
                  <Route path="/customer/:customerId" element={ <Customer />  } />
                  <Route path="/horse/:customerId/:horseId" element={ <EditHorse />  } />
                  <Route path="/horse/new/:userId" element={ <CreateHorse />  } />
                  <Route path="/customer/new" element={ <CreateUser newUserIsAdmin={false} />  } />
                  <Route path="/admin/new" element={ <CreateUser newUserIsAdmin={true} />  } />
                </Route>
                <Route path="/view/:horseId" element={ <ViewHorse />  } />
                <Route index element={ <Home /> } />
              </Route>
              <Route element={ <PublicRoute /> }>
                <Route path="/login" element={ <Login />  } />
                <Route path="/activate/:id/:token" element={ <Register />  } />
              </Route>
            </Route>
            <Route path="*" element={ <Navigate to="/" />  } />
          </Routes>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App
