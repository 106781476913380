import { Box, Button, Container, Divider, Stack, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { PatternFormat } from "react-number-format"
import { addUser } from "../Api/Admin_api"
import { useUser } from "../Context/User_context"
import { useNavigate } from "react-router-dom"

const CreateUser = (props: any) => {

    const { newUserIsAdmin } = props

    const { setNotification } = useUser()
    const navigate = useNavigate()

    const [userInfo, setUserInfo] = useState<any>({
        admin: newUserIsAdmin
    })

    const handleCreate = async () => {
        const result = await addUser(userInfo)
        if (result.type === "error") {
            setNotification({ type: result.type, msg: result.msg })
        } else {
            navigate(`/customer/${result.id}`)
        }
    }

    return (
        <Container sx={{ display: "flex", gap: 2, width: "100%" }}>
            <Stack gap={3} sx={{ flexGrow: 1 }}>
                <Typography variant="h4">New {newUserIsAdmin ? "Admin" : "Customer"}</Typography>
                <Divider />
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ paddingLeft: 1 }}>{newUserIsAdmin ? "Admin" : "Customer"} Email</Typography>
                    <TextField
                    required
                    placeholder={`Enter ${newUserIsAdmin ? "Admin" : "Customer"} Email`}
                    value={userInfo?.email ?? ""}
                    onChange={(e) => {setUserInfo((prev: any) => ({ ...prev, email: e.target.value }))}}
                    size="small"
                    sx={{ width: "100%" }}
                    />
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ paddingLeft: 1 }}>{newUserIsAdmin ? "Admin" : "Customer"} First Name</Typography>
                    <TextField
                    required
                    placeholder={`Enter ${newUserIsAdmin ? "Admin" : "Customer"} First Name`}
                    value={userInfo?.firstName ?? ""}
                    onChange={(e) => {setUserInfo((prev: any) => ({ ...prev, firstName: e.target.value }))}}
                    size="small"
                    sx={{ width: "100%" }}
                    />
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ paddingLeft: 1 }}>{newUserIsAdmin ? "Admin" : "Customer"} Last Name</Typography>
                    <TextField
                    required
                    placeholder={`Enter ${newUserIsAdmin ? "Admin" : "Customer"} Last Name`}
                    value={userInfo?.lastName ?? ""}
                    onChange={(e) => {setUserInfo((prev: any) => ({ ...prev, lastName: e.target.value }))}}
                    size="small"
                    sx={{ width: "100%" }}
                    />
                </Box>
                {   !newUserIsAdmin &&
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                        <Typography variant="h6" sx={{ paddingLeft: 1 }}>Customer Phone Number</Typography>
                        <PatternFormat
                        required
                        format="(###) ### ####" allowEmptyFormatting mask="_"
                        placeholder={`Enter Customer Phone Number`}
                        valueIsNumericString={false}
                        customInput={TextField}
                        value={userInfo?.phoneNumber ?? ""}
                        onChange={(e) => {setUserInfo((prev: any) => ({ ...prev, phoneNumber: e.target.value }))}}
                        sx={{ width: "100%" }}
                        size="small"
                        />
                    </Box>
                }
                <Divider />
                <Button onClick={handleCreate} variant="contained">Add {newUserIsAdmin ? "Admin" : "Customer"}</Button>
            </Stack>
        </Container>
    )
}

export default CreateUser