import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { useState } from "react"
import validator from "validator"
import { useUser } from "../Context/User_context"
// import { addUsers, createUser, deleteUsers, requestPasswordReset } from "../Api/User_api"
import IconButton from "@mui/material/IconButton"
import Alert from "@mui/material/Alert"
import { NumericFormat } from "react-number-format"
import InputAdornment from "@mui/material/InputAdornment"
import Divider from "@mui/material/Divider"
import InputBase from "@mui/material/InputBase"
import TextField from "@mui/material/TextField"
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox"
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend"
import Grow from "@mui/material/Grow"
import Checkbox from "@mui/material/Checkbox"
import { Button } from "@mui/material"
import { resetPasswordEmail } from "../Api/User_api"

interface IProps {
    open: boolean,
    onClose: () => void,
}

const ResetPasswordPanel = (props: IProps) => {

    const { setNotification } = useUser()

    const [errorMsg, setErrorMsg] = useState()
    const [email, setEmail] = useState("")

    const handleReset = async () => {
        const result = await resetPasswordEmail(email)
        if (result.type === "error") {
            setErrorMsg(result.msg)
        } else {
            setNotification({ type: result.type, msg: result.msg })
        }
    }

    return (
        <Dialog
        onClose={props.onClose}
        open={props.open}
        >
            <DialogTitle sx={{ textAlign: "center" }}>
                <Typography variant="h6">
                    Reset Password
                </Typography>
            </DialogTitle>
            <Stack sx={{ gap: 1, padding: 2 }}>
                <TextField
                size="small"
                label="email"
                value={email}
                onChange={(e) => {setEmail(e.target.value)}}
                />
                <Button
                variant="contained"
                onClick={handleReset}
                >
                    send reset email
                </Button>
                <Grow in={Boolean(errorMsg)}>
                    <Alert sx={{ marginBottom: "0px" }} severity="error">
                        {errorMsg}
                    </Alert>
                </Grow>
            </Stack>
        </Dialog>
    )
}

export default ResetPasswordPanel