import { Box, Button, Chip, Divider, IconButton, Paper, Stack, TextField, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import dayjs from "dayjs"
import { addCheckin, getCheckin, uploadFiles } from "../Api/Admin_api"
import { useUser } from "../Context/User_context"
import { useEffect, useRef, useState } from "react"

const CheckinCreateModal = (props: any) => {

    const { setNotification } = useUser()

    const { setMenu, selectedHorse, setUpdateRows, setSelectedHorse } = props

    const imageInputRef = useRef<HTMLInputElement>(null)
    const fileInputRef = useRef<HTMLInputElement>(null)

    const [files, setFiles] = useState<any>([])
    const [images, setImages] = useState<any>([])

    const [checkinInfo, setCheckinInfo] = useState<any>({
        note: "",
    })

    const handleSubmit = async () => {
        const result = await addCheckin(selectedHorse.id, { ...checkinInfo, services: [...selectedHorse.services, ...selectedHorse.serviceRequests], files: files.map((file: any) => file.name), images: images.map((file: any) => file.name) })
        if (result.type !== "error") {
            const fileUploadResult = await uploadFiles(selectedHorse.id, result.newCheckinId, files, images)
            if (fileUploadResult.type === "success") {
                setNotification({ type: result.type, msg: result.msg })
                
                // setUpdateRows(result.checkin.map((check: any) => ({
                //     ...check,
                // })))
                const myResult = await getCheckin(selectedHorse.id)
                // const myResultParsed = myResult.find((res: any) => res.id === result.newCheckinId)
                setUpdateRows(myResult)
                const parsedHorse = {
                    ...result.horse,
                    requests: result.horse.requests ? JSON.parse(result.horse.requests) : [],
                    services: result.horse.services ? JSON.parse(result.horse.services) : [],
                    images: result.horse.images ? JSON.parse(result.horse.images) : [],
                    files: result.horse.files ? JSON.parse(result.horse.files) : [],
                }
                setSelectedHorse(parsedHorse)
                setMenu(false)
            }
        } else {
            setNotification({ type: result.type, msg: result.msg })
        }
    }

    return (
        <Paper sx={{ display: "flex", flexDirection: "column", gap: 2, position: "absolute", width: "60vw", top: "50%", left: "50%", transform: "translate(-50%, -50%)", paddingX: 2, paddingY: 4 }}>
            <Box sx={{ display: "flex", width: "100%" }}>
                <Typography variant="h5">Check in - {dayjs().format("dddd, MMMM D, YYYY - h:mm A")}</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton onClick={() => {setMenu(false)}}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Divider />
            <Stack gap={1}>
                <Typography fontStyle="italic">Required Services for check-in*</Typography>
                <Box
                sx={{ display: "flex", width: "100%", gap: 1 }}
                >
                    {selectedHorse?.services?.map((field: any) => (
                        <Paper sx={{ borderRadius: 64, display: "flex", gap: 1, alignItems: "center", paddingRight: 2 }}>
                            <Chip label={field.name} />
                            <Typography variant="caption">{field.frequency}</Typography>
                        </Paper>
                    ))}
                    {selectedHorse?.serviceRequests.map((field: any) => (
                        <Paper sx={{ borderRadius: 64, display: "flex", gap: 1, alignItems: "center", paddingRight: 2, borderColor: "primary.main" }}>
                            <Chip label={JSON.parse(field.request).name} />
                            <Typography fontStyle={"italic"} variant="caption">{JSON.parse(field.request).frequency}</Typography>
                        </Paper>
                    ))}
                </Box>
            </Stack>
            <Stack gap={1}>
                <Typography>
                    Check in images:
                </Typography>
                <Box sx={{ display: "flex" }}>
                    {images.map((image: any, index: number) => (
                        <Chip key={index} label={image.name.length > 20 ? `${(image.name).substring(0, 20)}...` : image.name} onDelete={() => {
                            setImages((prev: any) => {
                                const updatedImages = [...prev]
                                updatedImages.splice(index, 1)
                                return updatedImages
                            })
                        }} />
                    ))}
                </Box>
                <input
                type="file"
                accept="image/*"
                ref={imageInputRef}
                style={{ display: "none" }}
                multiple
                onChange={(e: any) => {
                    const files = Array.from(e.target.files)
                    const imageFiles = files.filter((file: any) =>
                        file.type.startsWith("image/")
                    );
                    if (imageFiles.length === 0) {
                        setNotification({ type: "error", msg: "Not allowed to upload non image files." })
                    }
                    setImages((prev: any) => [...prev, ...imageFiles])
                }}
                />
                <Button startIcon={<AddPhotoAlternateIcon />} variant="outlined" onClick={() => {imageInputRef.current?.click()}}>Upload Images</Button>
            </Stack>
            <Stack>
                <Typography>
                    Check in note:
                </Typography>
                <TextField
                onChange={(e: any) => {setCheckinInfo((prev: any) => ({ ...prev, note: e.target.value }))}}
                value={checkinInfo.note}
                size="small"
                multiline
                minRows={12}
                />
            </Stack>
            <Stack gap={1}>
                <Typography>
                    Check in files:
                </Typography>
                <Box sx={{ display: "flex" }}>
                    {files.map((file: any, index: number) => (
                        <Chip key={index} label={file.name.length > 20 ? `${(file.name).substring(0, 20)}...` : file.name} onDelete={() => {
                            setFiles((prev: any) => {
                                const updatedFiles = [...prev]
                                updatedFiles.splice(index, 1)
                                return updatedFiles
                            })
                        }} />
                    ))}
                </Box>
                <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                multiple
                onChange={(e: any) => {
                    setFiles((prev: any) => [...prev, ...e.target.files])
                }}
                />
                <Button startIcon={<UploadFileIcon />} variant="outlined" onClick={() => {fileInputRef.current?.click()}}>Upload Files</Button>
            </Stack>
            <Box sx={{ display: "flex", width: "100%" }}>
                <Box sx={{ flexGrow: 1 }} />
                <Button variant="contained" onClick={handleSubmit}>
                    submit & email
                </Button>
            </Box>
        </Paper>
    )
}

export default CheckinCreateModal