import { Box, IconButton, InputBase, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"

import EditRoundedIcon from "@mui/icons-material/EditRounded"

const EditableItem = (props: any) => {

    const [activeEdit, toggleActiveEdit] = useState(false)
    const [text, updateText] = useState(props.children)

    const handleChange = (event: any) => {
        updateText(event.target.value)
    }

    const handleClick = () => {
        toggleActiveEdit(!activeEdit)
    }

    const handleBlur = () => {
        toggleActiveEdit(!activeEdit)
        props.onBlur && props.onBlur(text === "" ? "Name" : text)
        if (text === "") {
            updateText("Name")
        }
    }

    useEffect(() => {
        updateText(props.children)
    }, [props.children])

    return (
        <Box
        sx={{ display: "flex" }}
        >
            {activeEdit ?
            <TextField
            size="small"
            autoFocus
            value={text}
            onChange={handleChange}
            onBlur={handleBlur}
            sx={{
                fontSize: (props.variant ==="h4" ? "2.125rem" : undefined) || (props.variant === "h5" ? "1.5rem" : undefined),
                color: props.color,
                width: "auto",
            }}
            />
            :
            <Typography 
            onClick={handleClick} 
            variant={props.variant}
            sx={{
                py: 1,
                color: props.color,
            }}
            >
                {text}
            </Typography>}

            <IconButton
            onClick={() => {toggleActiveEdit(!activeEdit)}}
            >
                <EditRoundedIcon />
            </IconButton>
        </Box>
    )
}

export default EditableItem