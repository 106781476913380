import { Box, Button, Container, Divider, Stack, Tab, Tabs, TextField, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import { getUsers } from "../Api/Admin_api"
import { useUser } from "../Context/User_context"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import { useNavigate } from "react-router-dom"
import PriorityHighIcon from "@mui/icons-material/PriorityHigh"
import dayjs from "dayjs"

const dateFrequencyMap = (freq: string, updatedAt: Date)  => {
    const map: any = {
       "Daily": dayjs(updatedAt).add(1, "day"),
        // "Bi-Daily": new Date(updatedAt.getDate() + 1),
        // "Weekly": new Date(updatedAt.getDate() + 7),
        // "Bi-Weekly": new Date(updatedAt.getDate() + 4),
        // "Tri-Weekly": new Date(updatedAt.getDate() + 2),
        // "Daily": dayjs(updatedAt).add(1, "day"),
        // "Bi-Daily": dayjs(updatedAt).add(6, "hour"),
        // "Tri-Daily": dayjs(updatedAt).add(4, "hour"),
        "Weekly": dayjs(updatedAt).add(7, "day"),
        "Bi-Weekly": dayjs(updatedAt).add(4, "day"),
        "Tri-Weekly": dayjs(updatedAt).add(2, "day"),
    }
    return map[freq]
}

const Customers = () => {

    const { setNotification } = useUser()
    const navigate = useNavigate()

    const [selectedRow, setSelectedRow] = useState(0)

    const [customerSearchInput, setCustomerSearchInput] = useState("")
    const [rows, setRows] = useState([])
    const [visibleRows, setVisibleRows] = useState([])

    const [inactiveRows, setInactiveRows] = useState([])
    const [visibleInactiveRows, setVisibleInactiveRows] = useState([])

    useEffect(() => {
        getUsers().then((result: any) => {
            if (result.type === "error") {
                setNotification({ type: result.type, msg: result.msg })
            } else {
                setRows(result.activeUsers)
                setVisibleRows(result.activeUsers)
                setInactiveRows(result.inactiveUsers)
                setVisibleInactiveRows(result.inactiveUsers)
            }
        })
    }, [])

    const columns = [
        { field: "id", headerName: "ID" },
        { field: "email", headerName: "Email", flex: 1 },
        { field: "fname", headerName: "First Name", flex: 1 },
        { field: "lname", headerName: "Last Name", flex: 1 },
        { field: "phoneNumber", headerName: "Phone Number", flex: 1, renderCell: (params: any) => <>{params.row.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}</> },
        {
            field: "horseCount",
            headerName: "# of Horses",
            flex: 1,
            renderCell: (params: any) => (
                <Typography>{params.row.horses !== 0 ? params.row.horses : 0}</Typography>
            )
        },
        {
            field: "notif",
            headerName: "status",
            renderCell: (params: any) => (
                params.row.service && dayjs().isAfter(dateFrequencyMap(params.row.service.notifFrequency, params.row.updatedAt)) ? <PriorityHighIcon color="warning" /> : <></>
            )
        },
    ]

    return (
        <Container sx={{ display: "flex", gap: 2, width: "100%", height: "100%" }}>
            <Stack gap={3} sx={{ flexGrow: 1 }}>
                <Typography variant="h4">Customers</Typography>
                <Divider />
                <Stack gap={1} sx={{ flexGrow: 1 }}>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "flex-end" }}>
                        <Tabs value={selectedRow} onChange={(e, v: number) => {setSelectedRow(v)}}>
                            <Tab label="Active Customers" />
                            <Tab label="Inactive Customers" />
                        </Tabs>
                        <Box sx={{ display: "flex", gap: 1 }}>
                            <TextField size="small" placeholder="Search" value={customerSearchInput} onChange={(e: any) => {setCustomerSearchInput(e.target.value)}} />
                            <Button onClick={() => {navigate("/customer/new")}} startIcon={<AddCircleIcon />} size="small" variant="contained">new customer</Button>
                        </Box>
                    </Box>
                    {   selectedRow === 1 ?
                        <DataGrid
                        sx={{ flexGrow: 1 }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        getRowClassName={(params: any) => "enabled-row"}
                        columns={columns}
                        rows={visibleInactiveRows}
                        onRowClick={(p) => {navigate(`/customer/${p.row.id}`)}}
                        />
                        :
                        <DataGrid
                        sx={{ flexGrow: 1 }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        getRowClassName={(params: any) => "enabled-row"}
                        columns={columns}
                        rows={visibleRows}
                        onRowClick={(p) => {navigate(`/customer/${p.row.id}`)}}
                        />
                    }
                </Stack>
            </Stack>
        </Container>
    )
}

export default Customers