import { Box, Button, Chip, Divider, IconButton, Paper, Stack, TextField, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import dayjs from "dayjs"
import { addCheckin, getCheckin } from "../Api/Admin_api"
import { useUser } from "../Context/User_context"
import { useEffect, useState } from "react"
import CheckIcon from "@mui/icons-material/Check"
import { Download } from "@mui/icons-material"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import { parse } from "path"

const CheckinCreateModal = (props: any) => {

    const { setNotification } = useUser()

    const { setMenu, selectedHorse, selectedCheckin, setSelectedHorse } = props

    console.log(JSON.parse(selectedCheckin.services))

    const [images, setImages] = useState(selectedCheckin.images.map((item: any) => ({
        original: `${item.dir}?type=image`,
        thumbnail: `${item.dir}?type=image`,
    })))

    const handleDownload = async (link: string, fileName: string) => {
        try {
            const response = await fetch(link);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <Paper sx={{ display: "flex", flexDirection: "column", gap: 2, position: "absolute", width: "60vw", top: "50%", left: "50%", transform: "translate(-50%, -50%)", paddingX: 2, paddingY: 4 }}>
            <Box sx={{ display: "flex", width: "100%" }}>
                <Typography variant="h5">Check in - {dayjs(selectedCheckin.createdAt).format("dddd, MMMM D, YYYY - h:mm A")}</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton onClick={() => {setMenu(false)}}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Divider />
            <Stack>
                <Typography>
                    Check in images:
                </Typography>
                <Stack>
                    <ImageGallery items={images} additionalClass={"gallery"} />
                </Stack>
            </Stack>
            <Stack gap={1}>
                <Box
                sx={{ display: "flex", width: "100%", gap: 1 }}
                >
                    {JSON.parse(selectedCheckin.services).map((field: any) => (
                        <Paper sx={{ borderRadius: 64, display: "flex", gap: 1, alignItems: "center", overflow: "hidden", paddingRight: 1 }}>
                            <Chip label={field.name ?? JSON.parse(field.request).name} />
                            <Typography variant="caption">{field.frequency ?? JSON.parse(field.request).frequency}</Typography>
                            <CheckIcon sx={{ fontSize: "2em", color: "success.main" }} />
                        </Paper>
                    ))}
                </Box>
            </Stack>
            <Stack>
                <Typography>
                    Check in note:
                </Typography>
                <TextField
                value={selectedCheckin.note}
                size="small"
                multiline
                minRows={12}
                />
            </Stack>
            <Stack gap={1}>
                <Typography>
                    Check in files:
                </Typography>
                <Box sx={{ display: "flex" }}>
                    {selectedCheckin.files.map((file: any, index: number) => (
                        <Chip key={index} label={
                            <Box sx={{ display: "flex", gap: 1 }}>
                                <Typography>{file.name.length > 20 ? `${(file.name).substring(0, 20)}...` : file.name}</Typography>
                                <Download />
                            </Box>
                        }
                        onClick={() => {
                            handleDownload(`${file.dir}?type=file`, file.name)
                        }}
                        />
                    ))}
                </Box>
            </Stack>
        </Paper>
    )
}

export default CheckinCreateModal