import axios from "axios"
import { IUser } from "../Context/User_context"

export const auth = async () => {
    const result = await axios.get("/api/user/login")
    return result.data
}

export const login = async (email: string, password: string) => {
    const result = await axios.post("/api/user/login", {
        username: email,
        password: password,
    })
    return result.data
}

export const logout = async () => {
    const result = await axios.get("/api/user/logout")
    return result.data
}

export const activate = async (password: string, id: string, token: string) => {
    const result = await axios.post(`/api/user/activate/${id}/${encodeURIComponent(token)}`, {
        password: password,
    })
    return result.data
}

export const register = async (email: string, uname: string, password: string, form: any) => {
    const result = await axios.post("/api/user/register", {
        email: email,
        uname: uname,
        password: password,
        form: form,
    })
    return result.data
}

export const getUser = async () => {
    const result = await axios.get("/api/user/")
    return result.data
}

export const getUserByToken = async (token: number) => {
    const result = await axios.get(`/api/user/token/${token}`)
    return result.data
}

export const addService = async (horseId: number, requestData: any) => {
    const result = await axios.post(`/api/user/request/${horseId}`, {
        services: requestData
    })
    return result.data
}

export const resetPasswordEmail = async (email: string) => {
    const result = await axios.post(`/api/user/reset/`, {
        email: email
    })
    return result.data
}