import { useEffect, useState } from "react"
import { useUser } from "../Context/User_context"
import { useNavigate } from "react-router-dom"
import { Box, Button, Container, Stack, TextField, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { getUser } from "../Api/User_api"
import dayjs from "dayjs"

const Home = () => {

    const { user, setSelectedCustomer } = useUser()
    const navigate = useNavigate()

    const [rows, setRows] = useState([])

    useEffect(() => {
        if (user && user.status === 1) {
            navigate("/admin")
        } else {
            getUser().then((result: any) => {
                if (result.type !== "error") {
                    const parsedRows = result.Horses.map((horse: any) => {
                        const { checkins, ...other } = horse
                        return { ...other, checkins: [ result.checkins[0].filter((checkin: any) => checkin.horseId === horse.id ) ], files: result.files[0].files , images: result.files[0].images }
                    })
                    console.log(parsedRows)
                    setRows(parsedRows)
                    setSelectedCustomer(parsedRows)
                }
            })
        }
    }, [user])

    const columns = [
        { field: "id", headerName: "ID" },
        { field: "name", headerName: "Name", flex: 1 },
        { field: "dateAdmitted", headerName: "Arrived", flex: 1, renderCell: (params: any) => <>{dayjs(params.row.dateAdmitted).format("dddd, MMMM D, YYYY")}</> },
        { field: "dateLeaving", headerName: "Tentative Leaving Date", flex: 1, renderCell: (params: any) => <>{dayjs(params.row.dateLeaving).format("dddd, MMMM D, YYYY")}</> },
        { field: "updatedAt", headerName: "Last Update", flex: 1, renderCell: (params: any) => <>{dayjs(params.row.updatedAt).format("dddd, MMMM D, YYYY")}</> },
    ]
    
    return (
        <Container maxWidth="xl" sx={{ display: "flex", height: "100%", flexDirection: "column", gap: 2 }}>
            <Typography variant="h4">Welcome, {user?.fname}</Typography>
            <Stack gap={1} sx={{ flexGrow: 1 }}>
                <Box sx={{ display: "flex", width: "100%", alignItems: "stretch", gap: 2 }}>
                    <Typography variant="h5">My Horses</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField size="small" placeholder="search horses" />
                    {/* <Button variant="contained">new request</Button> */}
                </Box>
                <DataGrid
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                getRowClassName={(params: any) => "enabled-row"}
                columns={columns}
                rows={rows}
                onRowClick={(p) => {navigate(`/view/${p.row.id}`)}}
                />
            </Stack>
        </Container>
    )
}

export default Home