import { Container, Divider, Stack, Typography } from "@mui/material"

const Audits = () => {

    return (
        <Container sx={{ display: "flex", gap: 2, width: "100%" }}>
            <Stack gap={3} sx={{ flexGrow: 1 }}>
                <Typography variant="h4">Audits</Typography>
                <Divider />
            </Stack>
        </Container>
    )
}

export default Audits