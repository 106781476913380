import axios from "axios"

export const getUsers = async () => {
    const result = await axios.get("/api/admin/getUsers")
    return result.data
}

export const getUser = async (id: number | string) => {
    const result = await axios.get(`/api/admin/user/${id}`)
    return result.data
}

export const addUser = async (userInfo: any) => {
    const result = await axios.post("/api/admin/user/", userInfo)
    return result.data
}

export const addHorse = async (horseInfo: any) => {
    const result = await axios.post("/api/horse/", horseInfo)
    return result.data
}

export const addService = async (packageInfo: any) => {
    const result = await axios.post("/api/admin/service", packageInfo)
    return result.data
}

export const getService = async (id: number) => {
    const result = await axios.get(`/api/admin/service/${id}`)
    return result.data
}

export const getServices = async () => {
    const result = await axios.get("/api/admin/service")
    return result.data
}

export const deleteService = async (id: number) => {
    const result = await axios.delete(`/api/admin/service/${id}`)
    return result.data
}

export const getSettings = async () => {
    const result = await axios.get("/api/admin/settings")
    return result.data
}

export const updateSettings = async (settingsData: any) => {
    const result = await axios.post("/api/admin/settings", settingsData)
    return result.data
}

export const updateHorse = async (horseData: any, horseId: number | string) => {
    const result = await axios.post(`/api/horse/${horseId}`, horseData)
    return result.data
}

export const getCheckin = async (horseId: number | string) => {
    const result = await axios.get(`/api/admin/checkin/${horseId}`)
    return result.data
}

export const addCheckin = async (horseId: number | string, checkinData: any) => {
    const result = await axios.post(`/api/admin/checkin/${horseId}`, checkinData)
    return result.data
}

export const uploadFiles = async (horseId: number, checkinId: number | null, files: any, images: any) => {
    const formData = new FormData()

    images.forEach((image: any, index: number) => {
        formData.append(`images`, image);
    });

    files.forEach((file: any, index: number) => {
        formData.append(`files`, file);
    });

    const result = await axios.post(`/api/admin/upload/${horseId}/${checkinId}`, formData)
    return result.data
}