import { Alert, Box, Button, Grow, Paper, TextField, Typography } from "@mui/material"
import { useState } from "react"
import LoginIcon from "@mui/icons-material/Login"
import { Stack } from "@mui/system"
import { useUser } from "../Context/User_context"
import { useNavigate, useParams } from "react-router-dom"
import ResetPasswordPanel from "../Components/ResetPasswordPanel"
import { activate } from "../Api/User_api"

const Register = () => {

    const { id, token } = useParams()

    const { login } = useUser()
    const navigate = useNavigate()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [repeatPassword, setRepeatPassword] = useState("")
    const [alert, setAlert] = useState<string | undefined>()
    const [resetPanel, setResetPanel] = useState(false)

    const handleActivate = async () => {
        if (password === repeatPassword) {
            const result = await activate(password, id ?? "", token ?? "")
            if (result.type === "error") {
                setAlert(result.msg)
            } else {
                const loginResult = await login(email, password)
                if (loginResult.type === "error") {
                    navigate("/login")
                } else {
                    navigate("/")
                }
            }
        } else {
            setAlert("Passwords do not match")
        }
    }

    return (
        <Stack sx={{ justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
            <Grow in={Boolean(alert)}>
                <Alert severity="error" sx={{ marginTop: 6, width: "230px" }}>{alert}</Alert>
            </Grow>
            <Paper sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2, paddingTop: 2, paddingRight: 2, paddingBottom: 4, paddingLeft: 2, marginTop: 2 }}>
                <LoginIcon sx={{ fontSize: "4.5rem" }} />
                <Typography variant="h6">
                    Complete Account Activation.
                </Typography>
                <TextField
                label="Password"
                size="small"
                type="password"
                value={password}
                onChange={(e) => {setPassword(e.target.value)}}
                fullWidth
                />
                <TextField
                label="Repeat Password"
                size="small"
                type="password"
                value={repeatPassword}
                onChange={(e) => {setRepeatPassword(e.target.value)}}
                fullWidth
                />
                <Button onClick={handleActivate} variant="contained">
                    Activate
                </Button>
            </Paper>
        </Stack>
    )
}

export default Register