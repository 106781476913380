import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Chip, Container, Divider, Stack, TextField, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { addHorse, getServices, getSettings, getUsers, uploadFiles } from "../Api/Admin_api"
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { useUser } from "../Context/User_context"
import { useNavigate } from "react-router-dom"
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"

const CreateHorse = () => {

    const { setNotification } = useUser()

    const navigate = useNavigate()

    const imageInputRef = useRef<HTMLInputElement>(null)
    const fileInputRef = useRef<HTMLInputElement>(null)

    const [files, setFiles] = useState<any>([])
    const [images, setImages] = useState<any>([])

    const [horseInfo, setHorseInfo] = useState<any>({
        admitDate: dayjs(new Date()),
        leaveDate: null,
    })
    const [usernames, setUsernames] = useState<any>([])
    const [services, setServices] = useState([])

    const [selectedServices, setSelectedServices] = useState<any>([])
    const [selectedRequests, setSelectedRequests] = useState<any>([])

    const [facilities, setFacilities] = useState([
    ])

    const handleCreate = async () => {
        const result = await addHorse(horseInfo)
        if (result.type !== "error") {
            const fileUploadResult = await uploadFiles(result.horseId, null, files, images)
            if (fileUploadResult.type === "success") {
                setNotification({ type: result.type, msg: result.msg })
                navigate(-1)
            }
        } else {
            setNotification({ type: result.type, msg: result.msg })
        }
    }

    useEffect(() => {
        getUsers().then((result: any) => {
            if (result === "error") {
                setNotification({ type: result.type, msg: result.msg })
            } else {
                setUsernames([...result.activeUsers, ...result.inactiveUsers].map((user: any) => (user.status === 0 ? { ...user, label: `${user.fname} ${user.lname} (${user.email})` } : undefined)))
            }
        })
        getServices().then((result: any) => {
            if (result === "error") {
                setNotification({ type: result.type, msg: result.msg })
            } else {
                setServices(result.map((service: any) => ({ ...service, label: service.name })))
            }
        })
        getSettings().then((result: any) => {
            if (result === "error") {
                setNotification({ type: result.type, msg: result.msg })
            } else {
                setFacilities(result.facilities ? JSON.parse(result.facilities) : [])
            }
        })
    }, [])

    useEffect(() => {
        setHorseInfo((prev: any) => ({
            ...prev,
            services: selectedServices.map((id: number) => services.find((service: any) => service.id === id)),
            servicesRequests: selectedRequests.map((id: number) => services.find((service: any) => service.id === id)),
        }))
    }, [selectedServices, selectedRequests])

    return (
        <Container sx={{ display: "flex", gap: 2, width: "100%" }}>
            <Stack gap={3} sx={{ flexGrow: 1 }}>
                <Typography variant="h4">New Horse</Typography>
                <Divider />
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ paddingLeft: 1.75 }}>Date Admitted</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                        value={horseInfo.admitDate}
                        onChange={(e) => {setHorseInfo((prev: any) => ({ ...prev, admitDate: e }))}}
                        sx={{ width: "100%" }}
                        />
                    </LocalizationProvider>
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ paddingLeft: 1.75 }}>Tentative Leaving Date</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                        value={horseInfo.leaveDate}
                        onChange={(e) => {setHorseInfo((prev: any) => ({ ...prev, leaveDate: e }))}}
                        sx={{ width: "100%" }}
                        />
                    </LocalizationProvider>
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ paddingLeft: 1.75 }}>Horse Name</Typography>
                    <TextField value={horseInfo.name} onChange={(e) => {setHorseInfo((prev: any) => ({ ...prev, name: e.target.value }))}} placeholder="Enter Horse Name" size="small" sx={{ width: "100%" }} />
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ paddingLeft: 1.75 }}>Customer Name</Typography>
                    <Autocomplete
                    size="small"
                    sx={{ width: "100%" }}
                    options={usernames.sort((a: any, b: any) => -b.fname[0].localeCompare(a.fname[0]))}
                    groupBy={(option: any) => option.fname[0]}
                    renderInput={(params: any) => <TextField {...params} label="Customer" />}
                    onChange={(e: any, v: any) => {setHorseInfo((prev: any) => ({ ...prev, customer: v.id }))}}
                    />
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ paddingLeft: 1 }}>Facility</Typography>
                    <Autocomplete
                    size="small"
                    sx={{ width: "100%" }}
                    options={facilities.sort((a: any, b: any) => -b[0].localeCompare(a[0]))}
                    groupBy={(option: any) => option[0]}
                    renderInput={(params: any) => <TextField {...params} label="Facility" />}
                    onChange={(e: any, v: any) => {setHorseInfo((prev: any) => ({ ...prev, facility: v }))}}
                    />
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                    <Accordion sx={{ width: "100%", backgroundColor: "background.default", "&.MuiPaper-root": { borderRadius: "8px" } }}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant="h6">Included Services</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Divider sx={{ marginBottom: 2 }} />
                            <Stack gap={1}>
                                {services.map((service: any) => {
                                    const selected = selectedServices.some((item: number) => item === service.id)
                                    return (
                                        <Button
                                        sx={{ gap: 2 }}
                                        variant="outlined"
                                        fullWidth
                                        endIcon={selected ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
                                        onClick={(e: any) => {
                                            if (selected) {
                                                setSelectedServices((prev: any) => prev.filter((item: any) => item !== service.id ))
                                            } else {
                                                setSelectedServices((prev: any) => ([ ...prev, service.id ]))
                                            }
                                        }}
                                        >
                                            {service.name}
                                            <Chip size="small" label={service.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} />
                                            <Chip variant="outlined" size="small" label={service.frequency} />
                                            <Box sx={{ flexGrow: 1 }} />
                                        </Button>
                                    )
                                })}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                    <Accordion sx={{ width: "100%", backgroundColor: "background.default", "&.MuiPaper-root": { borderRadius: "8px" } }}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant="h6">Requestable Additional Services</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Divider sx={{ marginBottom: 2 }} />
                            <Stack gap={1}>
                                {services.map((service: any) => {
                                    const selected = selectedRequests.some((item: number) => item === service.id)
                                    return (
                                        <Button
                                        sx={{ gap: 2 }}
                                        variant="outlined"
                                        fullWidth
                                        endIcon={selected ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
                                        onClick={(e: any) => {
                                            if (selected) {
                                                setSelectedRequests((prev: any) => prev.filter((item: any) => item !== service.id ))
                                            } else {
                                                setSelectedRequests((prev: any) => ([ ...prev, service.id ]))
                                            }
                                        }}
                                        >
                                            {service.name}
                                            <Chip size="small" label={service.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} />
                                            <Chip variant="outlined" size="small" label={service.frequency} />
                                            <Box sx={{ flexGrow: 1 }} />
                                        </Button>
                                    )
                                })}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ paddingLeft: 1.75 }}>Internal Notes</Typography>
                    <TextField
                    sx={{ width: "100%" }}
                    InputProps={{
                        style: {
                            borderRadius: "24px",
                        }
                    }}
                    size="small"
                    multiline
                    minRows={4}
                    onChange={(e) => {setHorseInfo((prev: any) => ({ ...prev, notes: e.target.value }))}}
                    />
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column", gap: 1 }}>
                    <Typography variant="h6" sx={{ paddingLeft: 1.75 }}>Attached Images</Typography>
                    <Box sx={{ display: "flex" }}>
                        {images.map((image: any, index: number) => (
                            <Chip key={index} label={image.name.length > 20 ? `${(image.name).substring(0, 20)}...` : image.name} onDelete={() => {
                                setImages((prev: any) => {
                                    const updatedImages = [...prev]
                                    updatedImages.splice(index, 1)
                                    return updatedImages
                                })
                            }} />
                        ))}
                    </Box>
                    <input
                    type="file"
                    accept="image/*"
                    ref={imageInputRef}
                    style={{ display: "none" }}
                    multiple
                    onChange={(e: any) => {
                        const files = Array.from(e.target.files)
                        const imageFiles = files.filter((file: any) =>
                            file.type.startsWith("image/")
                        );
                        if (imageFiles.length === 0) {
                            setNotification({ type: "error", msg: "Not allowed to upload non image files." })
                        }
                        setImages((prev: any) => [...prev, ...imageFiles])
                    }}
                    />
                    <Button fullWidth startIcon={<AddPhotoAlternateIcon />} variant="outlined" onClick={() => {imageInputRef.current?.click()}}>Upload Images</Button>
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column", gap: 1 }}>
                    <Typography variant="h6" sx={{ paddingLeft: 1.75 }}>Attached Files</Typography>
                    <Box sx={{ display: "flex" }}>
                        {files.map((file: any, index: number) => (
                            <Chip key={index} label={file.name.length > 20 ? `${(file.name).substring(0, 20)}...` : file.name} onDelete={() => {
                                setFiles((prev: any) => {
                                    const updatedFiles = [...prev]
                                    updatedFiles.splice(index, 1)
                                    return updatedFiles
                                })
                            }} />
                        ))}
                    </Box>
                    <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    multiple
                    onChange={(e: any) => {
                        setFiles((prev: any) => [...prev, ...e.target.files])
                    }}
                    />
                    <Button fullWidth startIcon={<UploadFileIcon />} variant="outlined" onClick={() => {fileInputRef.current?.click()}}>Upload Files</Button>
                </Box>
                {/* <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ paddingLeft: 1.75 }}>Internal Attached Files</Typography>
                    <Button sx={{ width: "100%" }} variant="outlined">attach files</Button>
                </Box> */}
                <Divider />
                <Button onClick={handleCreate} variant="contained">Add Horse</Button>
            </Stack>
        </Container>
    )
}

export default CreateHorse